import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router';
import _ from 'underscore';
import { Global } from '~/App'
import API from '~/API';
import GriddleCasero from '~/components/GriddleCasero'
import moment from 'moment';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar } from 'recharts';

class Pagos extends Component {
    constructor() {
        super();
        this.state = {
            collapsed: false,
            datos: []
        }
        this.consultaPagos = this.consultaPagos.bind(this)
    }
    componentWillMount() {
        this.Redirect(this.props);
        this.consultaPagos(this.props);
    }
    componentWillReceiveProps(props) {
        this.Redirect(props)
        if (this.props.Servicio.empresa !== props.Servicio.empresa || this.props.Servicio.idServicio !== props.Servicio.idServicio) {
            this.consultaPagos(props);
        }
    }
    Redirect(props) {
        if (!this.props.Servicio.idServicio) {
            props.history.replace("/administra")
        }
    }
    async consultaPagos(props) {
        if (!props.Servicio.idServicio || !props.Servicio.empresa) {
            return;
        }
        var datos = await API.Call(`Consulta/${props.Servicio.empresa}/Cartola/Pagos/${props.Servicio.idServicio}/${moment().add(-13, 'month').format("DDMMYYYY")}/${moment().add(1, 'month').format("DDMMYYYY")}`);
        datos = _.map(datos, e => {
            e.fechaformato = moment(e.fecha).format("MMM YYYY")
            e.monto = e.haber - e.debe
            return e;
        }).reverse()
        this.setState({ datos });
    }
    render() {
        const dineroFormatter = ({ value }) => {
            return <div className="text-sm-right">{ value ? "$ " + Global.FormatearNumero(value) : "" }</div>;
        };
        const columns = [
            { key: "nombre_movimiento", name: "Detalle" },
            { key: "fecha", name: "Fecha", formatter: "fecha", right: true },
            // { key: "fecha_vcto", name: "Vencimiento", formatter: "fecha" },
            { key: "monto", name: "Monto", formatter: dineroFormatter, right: true }
        ];
        var rows = _.sortBy(this.state.datos || [], d => moment(d.fecha)).reverse();
        return (
            <div>
                <Row>
                    <Col sm={12}>
                        <ResponsiveContainer width={"100%"} height={250}>
                            <BarChart width={730} height={250} data={this.state.datos}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="fechaformato" />
                                <YAxis tickFormatter={t=>("$ "+Global.FormatearNumero(t))}/>
                                <Tooltip formatter={(value) => ["$"+Global.FormatearNumero(value),"Monto"]}/>
                                <Bar dataKey="monto" className="grafico-barras1" />
                            </BarChart>
                        </ResponsiveContainer>
                        <hr />
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className="bordeIzquierdo">
	                    <GriddleCasero
	                        data = {rows}
	                        columns = {columns}
	                    />
                    </Col>
                </Row>
            </div>
        )
    }
}


export default withRouter(Pagos);