import React, { Component } from 'react';
import { Spinner, Card, CardHeader, CardBody, Row, Col, Button } from 'reactstrap';
import { withRouter } from 'react-router';
import { Global } from '~/App'
import API from '~/API';
import moment from 'moment';
import _ from 'underscore';


class Suscripcion extends Component {
  constructor() {
    super();
    this.state = {
      cargando: true,
      datos: {}
    }
  }
  print() {
    window.print();
  }
  componentWillMount() {
    API.Call(`Recaudacion/${this.props.location.state.empresa}/Suscripcion/Detalle/${this.props.match.params.id}/${this.props.location.state.servicio}`)
      .then(datos => this.setState({ datos, cargando: false }))
      .catch(e => alert(e)) // Hacer algo con el error
  }
  render() {
    return (
      <Card>
        <CardHeader className="titulo-modal">Resultado de Transacción</CardHeader>
        <CardBody className="cuerpo-modal">
          <Row>
            <Col sm={12}>

              {this.state.cargando ? <Spinner animation="border" role="status">
                Cargando...
              </Spinner> :
                <div>
                        <Row>
                          <Col>
                            <h4>¡Gracias por realizar tu transacción con {this.state.datos.nombreEmpresa}!</h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p>El proceso de creación del mandato puede tomar algunos días. Por favor ten paciencia mientras se realizan las validaciones.</p>
                            <p>Estos son los detalles que hemos ingresado:</p>
                          </Col>
                        </Row>
                  <Row>
                    <Col sm={12} md={{ size: 8, offset: 2 }} className="bordeCompleto">
                      <Row>
                        <Col sm={4} className="text-sm-right">
                          <strong>Suscripcion</strong>
                        </Col>
                        <Col sm={8}>
                          {this.props.match.params.id}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={4} className="text-sm-right">
                          <strong>Recaudador</strong>
                        </Col>
                        <Col sm={8} className="text-left">
                          {this.state.datos.Origen}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={4} className="text-sm-right">
                          <strong>Estado</strong>
                        </Col>
                        <Col sm={8} className="text-left">
                          {this.state.datos.Activo?"Activo":"Inactivo"}
                        </Col>
                      </Row>
                      {this.state.datos.Datos ?
                      Object.entries(this.state.datos.Datos).map(([key,value]) => {
                              return <Row>
                              <Col sm={4} className="text-sm-right">
                                <strong>{key}</strong>
                              </Col>
                              <Col sm={8} className="text-left">
                                {value}
                              </Col>
                            </Row>
                            }):""}
                      {this.state.datos.IdServicio ?
                        <Row>
                          <Col sm={4} className="text-sm-right">
                            <strong>Cuenta</strong>
                          </Col>
                          <Col sm={8} className="text-left">
                            {this.state.datos.IdServicio}
                          </Col>
                        </Row> : ""}
                      <Row>
                        <Col sm={12} className="text-center">
                          <br />
                          <Button color="primary" onClick={this.print}>Imprimir</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              }

            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }
}


export default withRouter(Suscripcion);