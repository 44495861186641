import React, { Component } from 'react';
import { TabContent, Alert, CustomInput, InputGroup, InputGroupAddon, UncontrolledTooltip, TabPane, NavItem, FormFeedback, NavLink, Spinner, Form, FormGroup, Label, Row, Col, Input, Button, Card, CardHeader, CardFooter, CardBody, CardTitle, CardText } from 'reactstrap';
import { withRouter } from 'react-router';
import _ from 'underscore';
import { Global } from '~/App'
import API from '~/API';
import GriddleCasero from '~/components/GriddleCasero'
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';

class Correo extends Component {
    constructor(props) {
        super();
        this.state = {
            listo: props.Servicio.e_mail && props.Servicio.desactivar_email,
            datos: [],
            alerta: {}
        }
    }
    componentWillMount() {
        this.Redirect(this.props);
        if(this.props.Servicio.terminos_recepcion_doc){
            this.setState({aceptaTerminos:false})
        }
    }
    Redirect(props) {
        if (!this.props.Servicio.idServicio || this.props.Servicio.e_mail === undefined) {
            props.history.replace("/administra")
        }
    }
    render() {
        var servicio = this.props.Servicio;
        var Activo = this.state.activo !== undefined?this.state.activo:servicio.e_mail
        return (
            <div>
                        <Row>
                            <Col sm={12} className="bordeCompleto">
                                <h4>Envío de Documentos por E-Mail</h4>
                                <br/>
                                    <Row>
                                        <Col xs={12}>
                                        <FormGroup check>
                                            <Label check>
                                            <Input disabled={this.state.listo} checked={Activo} onChange={e => {
                                            this.setState({activo:e.target.checked})
                                        }} type="checkbox" />{' '}
                                        <b>
                                            Deseo recibir mis documentos por E-Mail
                                            </b>
                                            </Label>
                                        </FormGroup>
                                        </Col>
                                    </Row>
                                    <br/>
                                    {servicio.terminos_recepcion_doc && !servicio.e_mail && <Row>
                                        <Col xs={12}>
                                             <FormGroup check>
                                            <Label check>
                                            <Input disabled={this.state.listo} checked={this.state.aceptaTerminos} onChange={e => {
                                                this.setState({aceptaTerminos:e.target.checked})
                                            }} type="checkbox" />
                                                <small>
                                                    {' '+servicio.terminos_recepcion_doc}
                                                </small>
                                            </Label>
                                        </FormGroup>
                                        </Col>
                                    </Row>}
                                    <br/>
                                <Row>
                                    <Col sm={this.props.Usuario && this.props.IngresarLectura ? 6 : 3}>
                                        <Button block onClick={()=>{
                                            API.Call(`Consulta/${servicio.empresa}/Actualizar/EMail`, { IdServicio: servicio.idServicio, Activo }).then(() => {
                                                this.setState({ listo: true, alerta: { tipo: "success", mensaje: "Método de recepción modificado" } })
                                                this.props.history.replace({
                                                    pathname: '/detalle/email',
                                                    state: { servicio: {...servicio, e_mail: Activo} }
                                                  })
                                            }).catch(e => {
                                                this.setState({ alerta: { tipo: "danger", mensaje: "No se pudo hacer el cambio" } })
                                            })
                                        }} disabled={this.state.listo || this.state.activo===undefined || this.state.activo === servicio.e_mail || (this.state.aceptaTerminos!==undefined && !servicio.e_mail && !this.state.aceptaTerminos)} color="primary">Guardar</Button>
                                    </Col>
                                </Row>
                                {!_.isEmpty(this.state.alerta) ?<Row>
                                    <Col xs={12}>
                                    
                                                <Alert className="m-0 mt-4 p-1" color={this.state.alerta.tipo}>
                                                    <small>
                                                        {this.state.alerta.mensaje}
                                                    </small>
                                                </Alert>
                                    </Col>
                                </Row>: <br />}
                            </Col>
                        </Row>
            </div>
        )
    }
}


export default withRouter(Correo);