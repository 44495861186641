import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router';


class Error extends Component {
  constructor() {
    super();
    this.state = {
    }
  }
  componentWillMount() {
  }
  render() {
    return (
      <Card>
            <CardHeader className="titulo-modal">Error</CardHeader>
            <CardBody className="cuerpo-modal">
        <Row>
          <Col sm={12}>
              <div>
                <h4>Hemos encontrado un error</h4>                
                <p>{this.props.match.params.error.split("+").join(" ")}</p>
                {/* <p>{decodeURIComponent(this.props.match.params.error)}</p> */}
              </div>
          </Col>
        </Row>
      </CardBody>
      </Card>
    )
  }
}


export default withRouter(Error);