import React, { Component } from 'react';
// import { TabContent, TabPane, NavItem, NavLink, Nav, Form, FormGroup, Label, Row, Col, Input, Button } from 'reactstrap';
import { Global } from '~/App'
import Griddle, { plugins, RowDefinition, ColumnDefinition} from 'griddle-react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'underscore';

export default class GriddleCasero extends Component {
  render() {
    const styleConfig = {
        icons: {
        TableHeadingCell: {
            // sortDescendingIcon: <small>(desc)</small>,
            // sortAscendingIcon: <small>(asc)</small>,
        },
        },
        classNames: {
            Table: 'table',
            Row: 'row-class',
            Pagination: 'griddle-pagination row',
            PreviousButton: 'btn btn-primary col-5',
            NextButton: 'btn btn-primary col-5',
            PageDropdown: 'griddle-page-select col-2'
        },
        textProperties: {
            next: 'Anterior',
            previous: 'Siguiente',
            noResults: 'No se encontraron resultados'
        },
        styles: {             
        // Filter: { fontSize: 18 },
        // Table: { border: "2px solid #555 "},
        }
    }
    const rowDataSelector = (state, { griddleKey }) => {
        return state
                .get('data')
                .find(rowMap => rowMap.get('griddleKey') === griddleKey)
                .toJSON();
    };
    const enhancedWithRowData = connect((state, props) => {
        return {
            rowData: rowDataSelector(state, props)
        };
    });
    const FechaFormatter = ({value}) => {
        value = moment(value).format("DD/MM/YYYY");
        return <div className="text-sm-right">{value}</div>;
    };
    //esto no anda ahora.
    const TextoDerecha = ({value}) => {
        return <div className="text-sm-right">{value}</div>;
    };
    const DineroFormatter = ({ value, griddleKey, rowData }) => {
        return <div className="text-sm-right">{value ? "$ " + Global.FormatearNumero(value) : "$ 0"}</div>;
    };
    const HeadingRight = ({title}) => <div className="text-right">{title}</div>;
    return (
      <div style={{overflow:"auto"}}>
        <Griddle
            pageProperties={{
                currentPage: 1,
                pageSize: 12
              }}
            plugins={[plugins.LocalPlugin]}
            styleConfig={styleConfig}
            components={{
                PreviousButton:({ hasPrevious, onClick, style, className, text }) => hasPrevious ? (<button type="button" onClick={onClick} style={style} className={className}>{"Anterior"}</button>) : <div className="col-5"></div>,
                NextButton:({ hasNext, onClick, style, className, text }) => hasNext ? (<button type="button" onClick={onClick} style={style} className={className}>{"Siguiente"}</button>) : null,
                NoResults: ()=><>No se encontraron resultados</>,
                Layout: ({Table, Pagination, Filter, SettingsWrapper}) => <div><Table/>{this.props.data.length?<Pagination/>:null}</div>
            }}
            data={this.props.data}>    
            <RowDefinition>
                {_.map(this.props.columns, (c,i)=> <ColumnDefinition key={i} id={c.key} title={c.name} customHeadingComponent={c.right ? HeadingRight : null} customComponent={c.formatter ? c.formatter === "fecha" ? FechaFormatter : c.formatter === "dinero" ? DineroFormatter : c.formatter === "derecha" ? TextoDerecha : enhancedWithRowData(c.formatter)  : null} />)}
            </RowDefinition>
        </Griddle>
      </div>
    )
  }
}