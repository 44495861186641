import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './Layout/index';
import PagaTuCuenta from './PagaTuCuenta';
import Home from './Home';
import Registro from './Usuario/Registro';
import Recuperar from './Usuario/Recuperar';
import Reenviar from './Usuario/Reenviar';
import Usuario from './Usuario/Usuario';
import Administra from './Administra';
import Detalle from './DetalleCuenta';
import Documentos from './DetalleCuenta/Documentos';
import Correo from './DetalleCuenta/Correo';
import Pagos from './DetalleCuenta/Pagos';
import Consumos from './DetalleCuenta/Consumos';
import OrdenDeCompra from './Home/OrdenDeCompra';
import Suscripcion from './Home/Suscripcion';
import Error from './Home/Error';
import IngresaLectura from './IngresaLectura/Index';
import ScrollToTop from './ScrollToTop'
import Convenios from './DetalleCuenta/Convenios'; 
import Requerimientos from './DetalleCuenta/Requerimientos';
import Suscripciones from './DetalleCuenta/Suscripciones';

import 'react-toggle/style.css'
import './custom.css'
import 'react-select/dist/react-select.css';

var Global = {};

export { Global };

export default class App extends Component {
  constructor(props) {
    super()
    Global = {
      FormatearNumero(x) {
        return (x||0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },
      DV: function (T) {
        var M = 0, S = 1;
        for (; T; T = Math.floor(T / 10))
          S = (S + T % 10 * (9 - M++ % 6)) % 11;
        return S ? S - 1 : 'k';
      },
      AgregaDV: function (rut) {
        if (rut != null) {
          rut = rut.toString().split('-')[0].replace(/[.]/g, '');
          return rut + "-" + Global.DV(rut);
        } else {
          return "";
        }
      },
      ValidaRUT: function (rutCompleto) {
        if (!/^[0-9.]+-[0-9kK]{1}$/.test(rutCompleto))
          return false;
        var tmp = rutCompleto.split('-');
        var digv = tmp[1];
        var rut = tmp[0].replace(/[.]/g, '');
        if (digv == 'K') digv = 'k';
        return (Global.DV(rut) == digv);
      },
      ValidateEmail: function (email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      },
      toTitleCase(str) {
        return str && str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
      },
      Servicios: [],
      cargandoServicios: false,
      Empresas: [],
      ...props.portal
    }
  }
  static displayName = App.name;

  render() {
    return (
      <ScrollToTop>
        <Layout>
          <MyRoute exact path='/' Component={Home} />
          <MyRoute exact path='/lectura' Component={IngresaLectura} />
          <MyRoute path='/ordendecompra/:guid' Component={OrdenDeCompra} />
          <MyRoute path='/suscripcion/:id' Component={Suscripcion} />
          <MyRoute path='/error/:error' Component={Error} />
          <MyRoute exact path='/paga' Component={PagaTuCuenta} />
          <MyRoute path='/sucursal' Component={Administra} />
          <MyRoute path='/detalle' Component={Detalle}>
            <MyRoute path='/' exact Component={Documentos} />
            <MyRoute path='/documentos' exact Component={Documentos} />
            <MyRoute path='/pagos' exact Component={Pagos} />
            <MyRoute path='/consumos' exact Component={Consumos} />
            <MyRoute path='/email' exact Component={Correo} />
            <MyRoute path='/convenios' exact Component={Convenios} />
            <MyRoute path='/requerimientos' exact Component={Requerimientos} />
            <MyRoute path='/suscripciones' exact Component={Suscripciones} />
          </MyRoute>
          <MyRoute path='/registrate' Component={Registro} />
          <MyRoute path='/recuperar' Component={Recuperar} />
          <MyRoute path='/reenviar' Component={Reenviar} />
          <MyRoute path='/usuario' Component={Usuario} />
          <MyRoute Component={Home} />
        </Layout>
      </ScrollToTop>
    );
  }
}

class MyRoute extends React.Component {
  render() {
    var { Component, path, exact, passedProps } = this.props;
    return (
      <Route
        path={path}
        exact={exact}
        render={props => <Component {...passedProps}>
          {
            React.Children.map(this.props.children, child => {
              return React.cloneElement(child, { path: props.match.url + (child.props.path || ""), passedProps }) // para forzar la actualización
            }
            )
          }
        </Component>}
      />
    );
  }
}