import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router';
import _ from 'underscore';
import IngresaCuenta from '~/components/IngresaCuenta'
import Login from '~/Usuario/Login'

class Home extends Component {
  componentWillMount() {
    var queryParams = this.props.wixLocation||this.props.location.search;
    if (queryParams) {
      this.QueryStringRedirect(queryParams,this.props);
    } else {
      this.Redirect(this.props)
    }
  }
  componentWillReceiveProps(props) {
    if(!this.props.wixLocation && props.wixLocation){
      this.QueryStringRedirect(props.wixLocation,props);
    }else{
      this.Redirect(props)
    }
  }
  QueryStringRedirect(queryParams,props){
    try {
      var matchesOrdenCompra, matchesSuscripcion, matchesError, matchesToken, matchesUsuario, matchesEmpresa;
      if (matchesOrdenCompra = queryParams.match(/ordendecompra=((\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1})/)) {
        var guid = matchesOrdenCompra[1];
        props.history.replace(`/ordendecompra/${guid}`);
      }if (matchesSuscripcion = queryParams.match(/suscripcion=([^&]*).*?servicio=([^&]*).*?empresa=([^&]*)/i)) {
        props.history.replace({
          pathname:`/suscripcion/${matchesSuscripcion[1]}`,
          state: {empresa: matchesSuscripcion[3], servicio: matchesSuscripcion[2]}
        });
      }else if((matchesToken = queryParams.match(/token=([^&]*)/)) && (matchesEmpresa = queryParams.match(/empresa=([^&]*)/))){
        props.history.replace({
          pathname: '/paga',
          state: { empresa: matchesEmpresa[1], token: matchesToken[1] }
        })
      } else if (matchesError = queryParams.match(/error=([^&]*)/)) {
        var error = matchesError[1];
        props.history.replace(`/error/${error}`);
      } else if ((matchesUsuario = queryParams.match(/usuario=([^&]*)/)) && (matchesToken = queryParams.match(/token=([^&]*)/))) {
        props.history.replace({
          pathname: '/recuperar',
          state: { usuario: matchesUsuario[1], token: matchesToken[1] }
        });
      }
    } catch{ }
  }
  Redirect(props) {
    if (props.Usuario) {
      props.history.replace("/sucursal")
    }
  }
  render() {
    return (
      <Card>
        <CardHeader className="titulo-modal">Sucursal Virtual</CardHeader>
        <CardBody className="cuerpo-modal">
          <Row>
            <Col sm={6} className="bordeIzquierdoSimple">
              <h4>Clientes Registrados</h4>
              <h4 className="text-muted"><small>Ingrese con su usuario y contraseña</small></h4>
              <br />
              <Login />
            </Col>
            <Col sm={6} className="bordeDerecho">
              <h4 class="paga-tu-cuenta"><span>Paga tu Cuenta</span></h4>
              <h4 className="text-muted ingrese-datos-cuenta"><small><span>Ingrese los datos de su cuenta</span></small></h4>
              <br />
              <IngresaCuenta {...this.props} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }
}


export default withRouter(Home);