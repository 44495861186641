import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, ButtonGroup, FormGroup, InputGroup, Col, Label, Input, InputGroupAddon, FormFeedback, Card, CardFooter, CardHeader, CardBody, Row } from 'reactstrap';
import { Global } from '~/App'
import moment from 'moment'

export default class ModalDetalleConvenio extends Component {
    constructor() {
        super();
        this.state = {
            abrir: false,
            vistaActiva: 0
        }
        this.toggle = this.toggle.bind(this);
    }
    toggle(e) {
        e.preventDefault();
        this.setState({ abrir: !this.state.abrir });
    }
    render() {
        const closeBtn = <button className="close" onClick={this.toggle}>&times;</button>;
        var convenio = this.props.Convenio;
        if (convenio) {
            return (
                <div>
                    <Button block onClick={this.toggle}>Detalles Convenio</Button>
                    <Modal scrollable size="lg" wrapClassName="asyntec-portal-clientes" isOpen={this.state.abrir} toggle={this.toggle}>
                        <ModalHeader close={closeBtn}>
                            Detalle {convenio.Convenio.NomTipoConvenio}
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col sm={6}>
                                    <p>Tipo deuda: {convenio.TipoDeuda.NomTipoDeuda}</p>
                                </Col>
                                <Col sm={3}>
                                    <p>Valido desde: {moment(convenio.Convenio.FechaInicio).format('LT')}</p>
                                </Col>
                                <Col sm={3}>
                                    <p> hasta: {moment(convenio.Convenio.FechaFin).format('LT')}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <p>Forma de pago: {convenio.FormaPago.Nombre}</p>
                                </Col>
                                <Col sm={3}>
                                    <p>Antig maxima: {convenio.Convenio.AntigCaducacion}</p>
                                </Col>
                                <Col sm={3}>
                                    <p>Max ciclos: {convenio.Convenio.MaxCiclosGracia}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <p>Moneda: {convenio.Moneda.NomMoneda}</p>
                                </Col>
                                <Col sm={3}>
                                    <p>Emite pagaré: {convenio.Convenio.BEmitePagare ? "sí" : "no"}</p>
                                </Col>
                                <Col sm={3}>
                                    <p>Admite prepago: {convenio.Convenio.BPrepago ? "sí" : "no"}</p>
                                </Col>
                                <Col sm={3}>
                                    <p>Periodo vencimiento: {convenio.Convenio.PeriodoVcto}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <p>Tipo condonacion: {convenio.TipoCondonacion.Nombre}</p>
                                </Col>
                                <Col sm={2}>
                                    <p>Min: {convenio.Convenio.CondonacionMin}</p>
                                </Col>
                                <Col sm={2}>
                                    <p>Max: {convenio.Convenio.CondonacionMax}</p>
                                </Col>
                                <Col sm={2}>
                                    <p>Tope: {convenio.Convenio.PeriodoVcto}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <p>Tipo pie: {convenio.TipoPie.Nombre}</p>
                                </Col>
                                <Col sm={2}>
                                    <p>Min: {convenio.Convenio.PieMin}</p>
                                </Col>
                                <Col sm={2}>
                                    <p>Max: {convenio.Convenio.PieMax}</p>
                                </Col>
                                <Col sm={2}>
                                    <p>Tope: {convenio.Convenio.TopePieMin ? convenio.Convenio.TopePieMin : 'N/A'}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <p>Tipo interés: {convenio.TipoInteres.Nombre}</p>
                                </Col>
                                <Col sm={2}>
                                    <p>Min: {convenio.Convenio.InteresMin}</p>
                                </Col>
                                <Col sm={2}>
                                    <p>Max: {convenio.Convenio.InteresMax}</p>
                                </Col>
                                <Col sm={2}>
                                    <p>Tope: {convenio.Convenio.TopeInteres ? convenio.Convenio.TopeInteres : 'N/A'}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <p>Tipo cuota: {convenio.TipoCuota.Nombre}</p>
                                </Col>
                                <Col sm={2}>
                                    <p>Min: {convenio.Convenio.CuotaMin}</p>
                                </Col>
                                <Col sm={2}>
                                    <p>Max: {convenio.Convenio.CuotaMax}</p>
                                </Col>
                                <Col sm={2}>
                                    <p>Tope: {convenio.Convenio.TopeCuota}</p>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </Modal>
                </div>
            )
        } else {
            return null;
        }
    }
}