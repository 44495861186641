import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Col, Label } from 'reactstrap';
import GriddleCasero from '~/components/GriddleCasero'

export default class SimulacionConvenio extends Component {
    constructor() {
        super();
        this.state = {
            abrir: false,
            vistaActiva: 0
        }
        this.toggle = this.toggle.bind(this);
    }
    toggle(e) {
        e.preventDefault();
        this.setState({ abrir: !this.state.abrir });
    }
    render() {
        const closeBtn = <button className="close" onClick={this.props.Toggle}>&times;</button>;
        var rows = this.props.Cuotas;
        const columns = [
            { key: "no_cuota", name: "N Cuota", formatter: "derecha" },
            { key: "total_cuota", name: "Monto", formatter: "dinero" },
            // { key: "interes_conv", name: "Interes", formatter: "derecha" },
            // { key: "iva_interes", name: "Interes Iva", formatter: "derecha" },
            { key: "interes_total", name: "Interes", formatter: "derecha" },
            // { key: "monto_cuota", name: "Monto Cuota", formatter: "dinero" },
            { key: "monto_total_cuota", name: "Monto Cuota", formatter: "dinero" },
            { key: "saldo_conv", name: "Saldo", formatter: "dinero" }
        ];
        const griddle = <GriddleCasero data = {rows} columns = {columns} />
        return (
                this.props.SinModal ? griddle :
                <Modal scrollable size="lg" wrapClassName="asyntec-portal-clientes" isOpen={this.props.Show} toggle={this.props.Toggle}>
                    <ModalHeader close={closeBtn}>
                        Simulación
                    </ModalHeader>
                    <ModalBody style={{ whiteSpace: "nowrap"}}>
                        {griddle}
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </Modal>
        )
    }
}