import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Global } from '~/App'

export default class ModalImagen extends Component {
    constructor() {
        super();
        this.state = {
            abrir: false
        }
        this.toggle = this.toggle.bind(this);
    }
    toggle(e) {
        e.preventDefault();
        this.setState({ abrir: !this.state.abrir });
    }
    render() {
        var tipoImagen = this.props.TipoImagen ? this.props.TipoImagen : "imagenBoleta";
        var imagen = this.props.empresa && (Global.empresas.find(o => o.key === this.props.empresa) || {})[tipoImagen]
        if (imagen) {
            return (
                <div>
                    <a href="/" onClick={this.toggle}><small>¿Dónde encuentro estos datos?</small></a>
                    <Modal size="lg" wrapClassName="asyntec-portal-clientes" isOpen={this.state.abrir} toggle={this.toggle}>
                        <ModalBody>
                            <img width="100%" src={imagen} alt={"imagen"} />
                        </ModalBody>
                    </Modal>
                </div>
            )
        } else {
            return null;
        }
    }
}