import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { withRouter } from 'react-router';
import _ from 'underscore';
import { Global } from '~/App'
import API from '~/API';
import GriddleCasero from '~/components/GriddleCasero'
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar } from 'recharts';

class Documentos extends Component {
    constructor() {
        super();
        this.state = {
            collapsed: false,
            datos: [],
            cargando: true
        }
        this.ConsultaDocumentos = this.ConsultaDocumentos.bind(this)
        this.getDocumento = this.getDocumento.bind(this)
    }
    async getDocumento(idProceso, noDoc) {
        var win = window.open('about:blank', '_blank');
        try {
            var datos = await API.Call(`Consulta/${this.props.Servicio.empresa}/Documento/${this.props.Servicio.idServicio}/${idProceso}/${noDoc}`);
        } catch {
            win.close();
        }
        if (!win.closed) {
            var byteCharacters = atob(datos);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            let file = new Blob([byteArray], { type: "application/pdf;base64" });
            var blobHtmlElement;
            blobHtmlElement = document.createElement('object');
            blobHtmlElement.style.position = 'fixed';
            blobHtmlElement.style.top = '0';
            blobHtmlElement.style.left = '0';
            blobHtmlElement.style.bottom = '0';
            blobHtmlElement.style.right = '0';
            blobHtmlElement.style.width = '100%';
            blobHtmlElement.style.height = '100%';
            blobHtmlElement.setAttribute('type', 'application/pdf');
            blobHtmlElement.setAttribute('data', URL.createObjectURL(file));
            win.document.title = 'Documento Tributario Electrónico';
            win.document.body.appendChild(blobHtmlElement);
        }
    }
    componentWillMount() {
        this.Redirect(this.props);
        this.ConsultaDocumentos(this.props);
    }
    componentWillReceiveProps(props) {
        this.Redirect(props)
        if (this.props.Servicio.empresa != props.Servicio.empresa || this.props.Servicio.idServicio !== props.Servicio.idServicio) {
            this.ConsultaDocumentos(props);
        }
    }
    Redirect(props) {
        if (!this.props.Servicio.idServicio) {
            props.history.replace("/sucursal")
        }
    }
    async ConsultaDocumentos(props) {
        if (!props.Servicio.idServicio || !props.Servicio.empresa) {
            return;
        }
        this.setState({ cargando: true })
        var datos = await API.Call(`Consulta/${props.Servicio.empresa}/Cartola/Documentos/${props.Servicio.idServicio}/${moment().add(-13, 'month').format("DDMMYYYY")}/${moment().add(1, 'month').format("DDMMYYYY")}`);
        datos = _.map(datos, e => {
            e.fechaformato = moment(e.fecha).format("MMM YYYY")
            e.monto = e.debe - e.haber
            return e;
        }).reverse()
        this.setState({ datos, cargando: false });
    }
    render() {

        const pagableFormatter = ({ value, griddleKey, rowData }) => {
            return rowData.aPagar > 0 ?
                value ?
                    <Button tag={Link} to={{
                        pathname: "/paga",
                        state: { orden: griddleKey, servicio: this.props.Servicio.idServicio, empresa: this.props.Servicio.empresa }
                    }} color="primary">Pagar
                    </Button> :
                    <Button size="sm" color="primary" disabled>No disponible</Button> : null;
        };
        const imprimibleFormatter = ({ rowData }) => {
            var idProceso = rowData.id_proceso;
            var noDoc = rowData.numero;
            return <div>
                <Button onClick={() => this.getDocumento(idProceso, noDoc)} color="primary">Imprimir</Button>
            </div>;
        };
        const fechaFormatter = ({ value, columnId, rowData }) => {
            value = value ? moment(value) : null;
            return <div className={"text-sm-left" + (columnId == "fecha_vcto" && value < moment() && rowData.aPagar > 0 ? " text-danger" : "")}>{value ? value.format("DD/MM/YYYY") : null}</div>;
        };
        const columns = [
            { key: "nombre_movimiento", name: "Documento" },
            { key: "fechaformato", name: "Periodo" },
            { key: "fecha_vcto", name: "Vencimiento", formatter: fechaFormatter },
            { key: "monto", name: "Total Documento", right: true, formatter: "dinero" },
            { key: "aPagar", name: "Total Deuda", right: true, formatter: "dinero" },
            { key: "id", name: " ", formatter: imprimibleFormatter },
            { key: "pagable", name: " ", formatter: pagableFormatter }
        ];
        var rows = _.sortBy(this.state.datos || [], d => moment(d.fecha)).reverse();
        return (
            <div>
                <Row>
                    <Col sm={12}>
                        <div className={this.state.cargando ? "progress-bar progress-bar-striped progress-bar-animated bg-secondary" : ""} style={{ height: "1rem", marginTop: "-1rem" }}><small>{this.state.cargando ? "Cargando" : ""}</small></div>
                        <ResponsiveContainer width={"100%"} height={250}>
                            <BarChart width={730} height={250} data={this.state.datos}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="fechaformato" />
                                <YAxis tickFormatter={t => ("$ " + Global.FormatearNumero(t))} />
                                <Tooltip formatter={(value, name, props) => [<strong>{"$ " + Global.FormatearNumero(value)}</strong>, props.payload.nombre_movimiento]} />
                                <Bar dataKey="monto" className="grafico-barras1" />
                            </BarChart>
                        </ResponsiveContainer>
                        <hr />
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className="bordeIzquierdo">
                        <GriddleCasero
                            data={rows}
                            columns={columns}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}


export default withRouter(Documentos);