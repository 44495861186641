import React, { Component } from 'react';
import { Container, Spinner } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { Global } from '~/App';
import API from '~/API';
import { Switch } from 'react-router';

export class Layout extends Component {
  static displayName = Layout.name;

  constructor(){
    super();
    this.state={
      Usuario:null,
      cargando:false,
      Servicios:[],
      cargandoServicios:false,
      wixLocation:null
    }
    this.setUsuario = this.setUsuario.bind(this);
    Global.setUsuario = this.setUsuario;
    this.setServicios = this.setServicios.bind(this);
    Global.setServicios = this.setServicios;
    this.setCargandoServicios = this.setCargandoServicios.bind(this);
    Global.setCargandoServicios = this.setCargandoServicios;
  }

  componentWillMount(){
    try{
      var _this = this;
      window.onmessage = e=>e.data&&e.data.wixLocation&&_this.setState({wixLocation:e.data.wixLocation})
      window.parent.postMessage({listo:true},'*');
    }catch{}
    if(API.Cookie){
      this.setState({ cargando: true })      
      API.Call("Home/GetUser")
        .then(response => {
          if(response.ok){
            this.setUsuario(response.user)
            API.Call("Administra/Servicio/Listado").then(Servicios => {
              this.setState({ Servicios, cargando: false })
            }).catch(()=>this.setState({ cargando: false }))
          }
          else {
            this.setState({ cargando: false })
          }
        })
    }
  }
  setCargandoServicios(){
    Global.cargandoServicios = true;
    this.setState({cargandoServicios:true})
  }
  setServicios(Servicios){
    Global.Servicios = Servicios;
    Global.cargandoServicios = false;
    this.setState({cargandoServicios:false,Servicios});
  }


  setUsuario(Usuario){
    Global.Usuario = Usuario;
    this.setState({Usuario});
  }

  render () {
    return (
      this.state.cargando || this.state.cargandoServicios ? 
        <div className="text-center">
            <Spinner animation="border" role="status">{"Cargando..."}</Spinner>
        </div> :  
      <div>
        <NavMenu Usuario = {this.state.Usuario} Servicios = {this.state.Servicios}/>
        <Container fluid>
          <Switch>
          {
          React.Children.map(this.props.children, child =>{
            return React.cloneElement(child, { passedProps: {Servicios: this.state.Servicios, Usuario: this.state.Usuario, cargandoServicios:this.state.cargandoServicios, wixLocation:this.state.wixLocation }}) // para forzar la actualización
          }
          )
          }
          </Switch>
        </Container>
      </div>
    );
  }
}
