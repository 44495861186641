import React, { useState, useCallback } from "react"
import { useDropzone } from "react-dropzone"
import {Button, Spinner} from 'reactstrap';
import _ from 'underscore'

function UploadFile({ archivos, setArchivos, multiple }) {
	const [cargando, setCargando] = useState()

	const onDrop = useCallback(acceptedFiles => {
		setCargando(true)
		var af = (multiple ? acceptedFiles : acceptedFiles.length > 0 ? [acceptedFiles[0]] : []).map(file => {
			return (async () => {
				try {
					const { default: imageCompression } = await import('browser-image-compression');
					var f = await imageCompression(file, {
						maxSizeMB: 0.4,
						maxWidthOrHeight: 1920,
						useWebWorker: true
					})
					return new File([f], f.name, { type: f.type, lastModified: f.lastModified })
				} catch (e) {
					console.log(file,e)
					return file
				}
			})()
		})
		Promise.all(af).then(a => {
			setArchivos(multiple ? [...archivos, ...a] : a.length ? [a[0]] : [])
		}).finally(() => {
			setCargando(false)
		})
	}, [archivos])

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
	})

	const removeFile = file => () => {
		const newFiles = [...archivos]
		newFiles.splice(newFiles.indexOf(file), 1)
		setArchivos(newFiles)
	}

	const removeAll = () => {
		setArchivos([])
	}

	const files = archivos.map((file, i) => (
		<tr key={i}>
			<td style={{ textAlign: 'left', paddingRight: 5, paddingBottom: 5 }}>{file.name}</td>
			<td style={{ textAlign: 'right', paddingBottom: 5 }}><Button color="danger" size="sm" onClick={removeFile(file)}>Eliminar</Button></td>
		</tr>
	))

	return (
		<section>
			{cargando ? <Spinner animation="border" role="status">{"Cargando..."}</Spinner>: null}
			<div {...getRootProps({ className: "dropzone" })} style={{ padding: 0, borderWidth: "1px" }} multiple>
				<input {...getInputProps()} disabled={cargando} />
				<p>Arrastrar aquí o hacer click</p>
			</div>
			{files.length ? <aside>
				<hr />
				<table>
					<tbody>
						{files}
					</tbody>
				</table>
			</aside> : null}
			{files.length > 1 && <Button color="danger" size="sm" onClick={removeAll}>Eliminar Todos</Button>}
		</section>
	)
}

export default UploadFile