import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Alert, Card, CardHeader, CardBody, Spinner, NavItem, NavLink, Nav, Form, FormGroup, Label, Row, Col, Input, Button } from 'reactstrap';
import { Global } from '~/App';
import API from '~/API';
import serialize from 'form-serialize';
import _ from 'underscore';

class Usuario extends Component {
  constructor() {
    super();
    this.state = {
      errores: [],
      success: [],
      cargando: false
    }
  }
  componentWillMount() {
    this.Redirect(this.props)
  }
  componentWillReceiveProps(props) {
    this.Redirect(props)
  }
  Redirect(props) {
    if (!props.Usuario) {
      this.props.history.replace("/")
    }
  }
  Modificar(event) {
    event.preventDefault();
    var form = serialize(event.target, { hash: true });
    this.setState({ cargando: true })
    form.Empresa = Global.nombre;
    API.Call("Home/ModificarUsuario", form)
      .then(response => {
        Global.setUsuario(response.user);
        this.setState({ success: [response.mensaje], errores: [], cargando: false })
      })
      .catch(error => {
        this.setState({ cargando: false, errores: [error] })
      })
  }
  render() {
    return (this.props.Usuario &&
      <Card>
        <CardHeader className="titulo-modal">Mi Perfil</CardHeader>
        <CardBody className="cuerpo-modal">
          <Row>
            <Col sm={{size:8,offset:2}} className="bordeCompleto">
              <FormGroup row>
                <Label for="Correo" sm={3} className="text-sm-right">Correo</Label>
                <Col sm={9}>
                  <Input readOnly bsSize="sm" type="text" name="Correo" value={this.props.Usuario.email} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="RUT" sm={3} className="text-sm-right">{"RUT"}</Label>
                <Col sm={9}>
                  <Input readOnly bsSize="sm" type="text" name="RUT" value={this.props.Usuario.rut} />
                </Col>
              </FormGroup>
              <Form onSubmit={this.Modificar.bind(this)}>
                <input type="hidden" name="Email" value={`${this.props.Usuario.email}`}></input>
                {_.map(this.state.errores, (e, i) => <Alert key={i} color={"warning"}>{e}</Alert>)}
                {_.map(this.state.success, (e, i) => <Alert key={i} color={"success"}>{e}</Alert>)}
                <FormGroup row>
                  <Label for="Nombre" sm={3} className="text-sm-right">{"Nombre"}</Label>
                  <Col sm={9}>
                    <Input bsSize="sm" type="text" name="Nombre" placeholder={`${this.props.Usuario.nombre}`} />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="Apellido" sm={3} className="text-sm-right">{"Apellido"}</Label>
                  <Col sm={9}>
                    <Input bsSize="sm" type="text" name="Apellido" placeholder={`${this.props.Usuario.apellido}`} />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="Teléfono" sm={3} className="text-sm-right">{"Teléfono"}</Label>
                  <Col sm={9}>
                    <Input bsSize="sm" type="number" name="Teléfono" placeholder={`${this.props.Usuario.phoneNumber}`} />
                  </Col>
                </FormGroup>

                <Button className="float-right" type="submit" color="primary">Actualizar Datos</Button>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }
}

export default withRouter(Usuario);