import React, { Component } from 'react';
import { Alert, Spinner, Modal, ModalBody, FormFeedback, Card, CardHeader, CardBody, NavItem, NavLink, Nav, Form, FormGroup, Label, Row, Col, Input, Button } from 'reactstrap';
import _ from 'underscore';
import { withRouter } from 'react-router';
import serialize from 'form-serialize';
import API from '~/API';
import { Link } from 'react-router-dom';
import { Global } from '~/App';
import ModalImagen from '~/components/ModalImagen';

class Registro extends Component {
    static displayName = Registro.name;

    constructor(props) {
        super(props);
        this.state = {
            errores: [],
            cargando: false,
            password: "",
            password2: "",
            nombre: null,
            apellido: null,
            correoValido: false,
            rut: null,
            telefono: null,
            politicaAceptada: Global.Politicas ? false : true,
            abrirPoliticas: false
        };
    }
    validarDatos(form) {
        var errores = [];
        if (!form.Mail) {
            errores.push("Debes indicar Correo");
        }
        if (!form.Password) {
            errores.push("Debes indicar Contraseña");
        }
        if (!form.ConfirmPassword) {
            errores.push("Debes confirmar tu Contraseña");
        }
        if (!form.Nombre) {
            errores.push("Debes indicar tu Nombre");
        }
        if (!form.Apellido) {
            errores.push("Debes indicar tu Apellido");
        }
        if (!form.Telefono) {
            errores.push("Debes indicar tu Teléfono");
        }
        if (!form.Rut) {
            errores.push("Debes indicar tu Rut");
        }
        if (form.Password !== form.ConfirmPassword) {
            errores.push("Las Contraseñas tienen que coincidir")
        }
        this.setState({ errores });
        return _.isEmpty(errores);
    }
    Registrar(event) {
        event.preventDefault();
        var form = serialize(event.target, { hash: true });
        if (this.validarDatos(form)) {
            this.setState({ cargando: true })
            form.Empresa = Global.nombre;
            API.Call("Home/Register", form)
                .then(response => {
                    this.setState({ success: response.mensaje, cargando: false })
                })
                .catch(error => {
                    this.setState({ cargando: false, errores: [error]})
                })
        }
    }
    render() {
        return (
            <div>
                {this.state.cargando ?
                    <div>
                        <Spinner animation="border" role="status">{"Cargando..."}</Spinner>
                    </div> :
                    this.state.success ?
                        <div>
                            <Row>
                                <h4>{"Registro Correcto"}</h4>
                            </Row>
                            <Row>
                                <h4>{this.state.success}</h4>
                            </Row>
                        </div> :
                        <Card>
                            <CardHeader className="titulo-modal">Regístrate</CardHeader>
                            <CardBody className="cuerpo-modal">
                                <Row>
                                    <Col sm={8} className="bordeCompleto">
                                        <div className="text-sm-right">
                                            <Link to={"/administra"}>{"Ya tienes cuenta? Inicia Sesión"}</Link>
                                        </div>
                                        <br />
                                        <Form className="text-sm-right" onSubmit={this.Registrar.bind(this)}>
                                            {_.map(this.state.errores, (e, i) => <Alert key={i} color={"warning"}>{e}</Alert>)}
                                            <FormGroup row>
                                                <Label for="Mail" sm={3}>{"Correo"}</Label>
                                                <Col sm={9}>
                                                    <Input invalid={!this.state.correoValido} onChange={e => this.setState({ correoValido: Global.ValidateEmail(e.target.value) })} bsSize="sm" type="email" name="Mail" />
                                                    <FormFeedback>Debe ser un correo válido</FormFeedback>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label for="Password" sm={3}>{"Contraseña"}</Label>
                                                <Col sm={9}>
                                                    <Input invalid={this.state.password.length < 8} onChange={e => this.setState({ password: e.target.value })} bsSize="sm" type="password" name="Password" />
                                                    <FormFeedback>La contraseña debe tener al menos 8 caracteres</FormFeedback>
                                                    {/* <p className="m-0 small">Debe contener al menos una mayúscula</p>
                                                    <p className="m-0 small">Debe contener al menos un dígito</p> */}
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label for="ConfirmPassword" sm={3}>{"Confirma Contraseña"}</Label>
                                                <Col sm={9}>
                                                    <Input invalid={this.state.password != this.state.password2} onChange={e => this.setState({ password2: e.target.value })} bsSize="sm" type="password" name="ConfirmPassword" />
                                                    <FormFeedback>Las contraseñas deben coincidir</FormFeedback>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label for="Nombre" sm={3}>{"Nombre"}</Label>
                                                <Col sm={9}>
                                                    <Input invalid={!this.state.nombre} onChange={e => this.setState({ nombre: e.target.value })} bsSize="sm" type="text" name="Nombre" />
                                                    <FormFeedback>El nombre no puede estar vacío</FormFeedback>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label for="Apellido" sm={3}>{"Apellido"}</Label>
                                                <Col sm={9}>
                                                    <Input invalid={!this.state.apellido} onChange={e => this.setState({ apellido: e.target.value })} bsSize="sm" type="text" name="Apellido" />
                                                    <FormFeedback>El apellido no puede estar vacío</FormFeedback>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label for="Telefono" sm={3}>{"Teléfono"}</Label>
                                                <Col sm={9}>
                                                    <Input invalid={(this.state.telefono || "").length < 9} onChange={e => this.setState({ telefono: e.target.value })} bsSize="sm" type="text" name="Telefono" />
                                                    <FormFeedback>Debe tener al menos 9 caracteres</FormFeedback>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label for="Rut" sm={3}>{"Rut"}</Label>
                                                <Col sm={9}>
                                                    <Input invalid={!Global.ValidaRUT(this.state.rut)} onChange={e => this.setState({ rut: e.target.value })} bsSize="sm" type="text" name="Rut" />
                                                    <FormFeedback>Debe ser un RUT válido</FormFeedback>
                                                </Col>
                                            </FormGroup>
                                            {Global.politicas && <FormGroup>
                                                <Col sm={12}>
                                                    <Label check>
                                                        <Input type="checkbox" checked={this.state.politicaAceptada} onChange={() => this.setState({ politicaAceptada: !this.state.politicaAceptada })} />
                                                    Acepto los <a href="#" onClick={e => { e.preventDefault(); this.setState({ abrirPoliticas: !this.state.abrirPoliticas }) }}>Términos y Condiciones</a>.
                                                </Label>
                                                    <Modal size="lg" className="TerminosCondiciones" wrapClassName="asyntec-portal-clientes" isOpen={this.state.abrirPoliticas} toggle={() => this.setState({ abrirPoliticas: !this.state.abrirPoliticas })}>
                                                        <ModalBody >
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <div dangerouslySetInnerHTML={{ __html: Global.politicas }} />
                                                                </Col>
                                                            </Row>
                                                        </ModalBody>
                                                    </Modal>
                                                </Col>
                                            </FormGroup>}
                                            <Row>
                                                <Col xs={{ size: 9, offset: 0 }}>
                                                    <Button disabled={!Global.ValidaRUT(this.state.rut) || this.state.telefono < 9 || this.state.password.length < 8 || this.state.password != this.state.password2 || !this.state.nombre || !this.state.apellido || !this.state.correoValido || !this.state.politicaAceptada} color="primary" type="submit">{"Registrar"}</Button>
                                                </Col>
                                                {this.props.FuncionCerrar ?
                                                    <Col xs={4}>
                                                        <Button color="secondary" onClick={() => { this.props.FuncionCerrar() }}>Cancelar</Button>
                                                    </Col> : ""}
                                            </Row>
                                        </Form>
                                    </Col>
                                    <Col sm={4} className="border-left">
                                        <h4 className="text-muted"><small>En la Sucursal Virtual podrás:</small></h4>
                                        <br />
                                        <ul className="list-unstyled lead">
                                            <li>- Revisar tus cuentas en un sólo lugar</li>
                                            <li>- Ver los documentos históricos, pagos y consumos</li>
                                            <li>- Pagar tus cuentas sin tener un documento a la vista</li>
                                            <li>- Informar tus consumos</li>
                                        </ul>
                                    </Col>
                                </Row>
                            </CardBody></Card>}

            </div>
        );
    }
}

export default withRouter(Registro);
