import React, { Component } from 'react';
import { TabContent, Alert, CustomInput, InputGroup, InputGroupAddon, UncontrolledTooltip, TabPane, NavItem, FormFeedback, NavLink, Spinner, Form, FormGroup, Label, Row, Col, Input, Button, Card, CardHeader, CardFooter, CardBody, CardTitle, CardText } from 'reactstrap';
import { withRouter } from 'react-router';
import _ from 'underscore';
import { Global } from '~/App'
import API from '~/API';
import GriddleCasero from '~/components/GriddleCasero'
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';

class Convenios extends Component {
    constructor() {
        super();
    }
    componentWillMount() {
        this.Redirect(this.props);
    }
    Redirect(props) {
        if (!this.props.Servicio.idServicio || (this.props.Convenios || []).length == 0) {
            props.history.replace("/administra")
        }
    }
    render() {
        var Convenios = this.props.Convenios;
        const columns = [
            { key: "TipoConvenio", name: "Convenio" },
            { key: "FechaConvenio", name: "Fecha Suscripción", formatter: "fecha", right: true },
            { key: "MontoConvenido", name: "Monto Convenido", right: true, formatter: "dinero" },
            { key: "MontoPie", name: "Pie", right: true, formatter: "dinero" },
            { key: "NoCuotas", name: "Cuotas", right: true, formatter: "derecha" },
            { key: "Saldo", name: "Saldo", right: true, formatter: "dinero" },
        ];
        var rows = _.sortBy(Convenios || [], d => moment(d.FechaConvenio)).reverse();
        return (
            <div>
                <Row>
                    <Col sm={12} className="bordeIzquierdo">
                        <GriddleCasero
                            data={rows}
                            columns={columns}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}


export default withRouter(Convenios);