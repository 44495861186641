import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Card, CardHeader, CardBody, Collapse, FormGroup, Label, Input } from 'reactstrap';
import { withRouter } from 'react-router';
import { Global } from '~/App';
import _ from 'underscore';
import API from '~/API';
import GriddleCasero from '~/components/GriddleCasero'
import moment from 'moment';
import InputCustomFieldForm from './InputCustomFieldForm';
import ReCAPTCHA from "react-google-recaptcha";

const tiposRequerimientos = {}

const CreaReiteracion = ({ evento, Servicio }) => {
    const { tipo_evento } = evento || {}
    const [tipo, setTipo] = useState()
    const [abre, setAbre] = useState()
    const [captcha, setCaptcha] = useState()
    useEffect(() => {
        (async () => {
            if (!tiposRequerimientos.campos) tiposRequerimientos.campos = await API.Call(`Consulta/${Servicio.empresa}/Requerimientos/Campos`)
            setTipo({ ...tiposRequerimientos.campos.find(e => e.id_tipo_evento == tipo_evento) })
        })()
    }, [])

    if (!tipo) return <></>

    return <>
        <diV className="text-right pb-2"><Button size="sm" onClick={() => setAbre(true)}>Agregar un nuevo comentario</Button></diV>

        <Modal scrollable size="lg" wrapClassName="asyntec-portal-clientes" isOpen={abre} toggle={() => setAbre(false)}>
            <ModalHeader className="titulo-modal" close={<button style={{ color: "#fff" }} className="close" onClick={() => setAbre(false)}>&times;</button>}>
                Agregar un nuevo Comentario
            </ModalHeader>
            <ModalBody style={{ whiteSpace: "nowrap" }}>
                <FormGroup>
                    <Label className={!tipo.comentario ? "text-danger" : ""} for="desc">Comentario *</Label>
                    <Input type="textarea" name="comentario" value={tipo.comentario} onChange={e => setTipo({ ...tipo, comentario: e.target.value })} id="desc" />
                </FormGroup>
                {_.map(_.sortBy(_.groupBy(tipo.campos || [], cp => cp.Categoria), c => c[0].Categoria), cat => {
                    return <Card>
                        <CardHeader style={{ padding: "1em" }} className="titulo-modal">{cat[0].Categoria}</CardHeader>
                        <CardBody>
                            {cat.filter(cf => cf.FieldId > -2).map(cf => {
                                return <InputCustomFieldForm
                                    customField={cf}
                                    setCustomFieldValue={e => {
                                        var campos = [...tipo.campos]
                                        var index = campos.findIndex(f => f.FieldId == cf.FieldId)
                                        campos[index] = {
                                            ...campos[index],
                                            Value: e,
                                            OptionKey: e
                                        }
                                        setTipo({ ...tipo, campos })
                                    }} />
                            })}
                        </CardBody>
                    </Card>

                })}
            </ModalBody>
            <ModalFooter>
                <ReCAPTCHA
                    sitekey={Global.apiKeyCaptcha}
                    onChange={value => setCaptcha(value)}
                />
                <Button color="primary" onClick={e => {
                    e.preventDefault();
                    API.Call(`Consulta/${Servicio.empresa}/Requerimientos/${Servicio.idServicio}/Agregar`, {
                        id_evento: evento.id_evento,
                        campos: tipo?.campos.map(d => ({ Value: d.Value, OptionKey: d.OptionKey, FieldId: d.FieldId })),
                        comentario: tipo?.comentario,
                        captcha
                    })
                }} disabled={!captcha || tipo?.campos?.some(o => o.Mandatory && !o.Value) || !tipo?.comentario}>Enviar</Button>
            </ModalFooter>
        </Modal>
    </>
}

const ModalComentarios = ({ evento, Servicio, close }) => {
    const columns = [
        { key: "fecha", name: "Fecha", formatter: ({ value }) => moment(value).format("DD/MM/YYYY hh:mm") },
        { key: "tipo", name: "Origen", formatter: ({ value }) => value ? "Cliente" : "Empresa" },
        { key: "observacion", name: "Observacion" }
    ]
    return <Modal scrollable size="lg" wrapClassName="asyntec-portal-clientes" isOpen={evento} toggle={close}>
        <ModalHeader className="titulo-modal" close={<button style={{ color: "#fff" }} className="close" onClick={close}>&times;</button>}>
            Comentarios Requerimiento {evento?.numero}
        </ModalHeader>
        <ModalBody style={{ whiteSpace: "nowrap" }}>
            {Servicio.Empresa?.otros?.Requerimientos?.Reiteracion && evento?.cerrado === false ?
                <CreaReiteracion Servicio={Servicio} evento={evento} />
                : null}
            <GriddleCasero
                data={evento?.comentarios}
                columns={columns}
            />
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
    </Modal>
}

const Requerimientos = (props) => {

    const [requerimientos, setRequerimientos] = useState([])
    const [evento, setEvento] = useState()

    const columns = [
        { key: "numero", name: "Numero" },
        { key: "categoria", name: "Categoría" },
        { key: "clasificacion", name: "Clasificación" },
        { key: "subclasificacion", name: "Subclasificación" },
        { key: "cerrado", name: "Estado", formatter: ({ value, griddleKey, rowData }) => value ? "Cerrado" : "Abierto" },
        { key: "creacion", name: "Fecha Creacion", formatter: ({ value }) => moment(value).format("DD/MM/YYYY") },
        { key: "estimado_solucion", name: "Fecha Esperada Solución", formatter: ({ value }) => value ? moment(value).format("DD/MM/YYYY") : null },
        { key: "solucion", name: "Fecha Solución", formatter: ({ value }) => value ? moment(value).format("DD/MM/YYYY") : null },
        { key: "comentarios", name: " ", formatter: ({ rowData }) => <Button onClick={() => setEvento(rowData)} size="sm">Comentarios</Button> },
    ];

    useEffect(() => {
        if (!props.Servicio.idServicio) {
            props.history.replace("/administra")
        } else {
            API.Call(`Consulta/${props.Servicio.empresa}/Requerimientos/${props.Servicio.idServicio}`).then(ev => {
                setRequerimientos(ev.map(e => ({ ...e, numero: `${e.localidad}-${e.folio}` })))
            });
        }

    }, [])
    return <>
        <Row>
            <Col sm={12} className="bordeIzquierdo">
                <GriddleCasero
                    data={requerimientos}
                    columns={columns}
                />
                <ModalComentarios Servicio={props.Servicio} evento={evento} close={() => setEvento()} />
            </Col>
        </Row>
    </>
}


export default withRouter(Requerimientos);