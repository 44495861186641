import React, { Component } from 'react';
import { Alert, Modal, ModalBody, Form, FormGroup, Label, Row, Col, Input, Button } from 'reactstrap';
import _ from 'underscore';
import { Link } from 'react-router-dom';
import API from '~/API';
import serialize from 'form-serialize';
import Registro from './Registro';
import { Global } from '~/App'

export default class Login extends Component {
    _isMounted = false;    
    constructor() {
        super();
        this.state = {
            errores: [],
            cargando: false,
            abreModalRegistro: false
        }
        this.toggleModalRegistro = this.toggleModalRegistro.bind(this);
    }
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    safelySetState(params, callback = null){
        if(this._isMounted)
            this.setState(params, callback);
    }
    validarDatos(form) {
        var errores = [];
        if (!form.Mail) {
            errores.push("Debes indicar Correo");
        }
        if (!form.Password) {
            errores.push("Debes indicar Contraseña");
        }
        this.safelySetState({ errores });
        return _.isEmpty(errores);
    }
    Loguear(event) {
        event.preventDefault();
        var form = serialize(event.target, { hash: true });
        if (this.validarDatos(form)) {
            this.safelySetState({ cargando: true })
            API.Call("Home/Login", form)
                .then(async response => {
                    await API.SetHeaderBearer(response.token, response.expires)
                    Global.setCargandoServicios()
                    Global.setUsuario(response.user);
                    var Servicios = await API.Call("Administra/Servicio/Listado")
                    Global.setServicios(Servicios);
                    this.safelySetState({ cargando: false })
                })
                .catch(error => {
                    this.safelySetState({ cargando: false, errores: [error] });
                })
        }
    }
    toggleModalRegistro() {
        this.safelySetState({ abreModalRegistro: !this.state.abreModalRegistro });
    }
    render() {
        return (<div>
            <Form className="text-sm-right" onSubmit={this.Loguear.bind(this)}>
        {_.map(this.state.errores, (e, i) => <Alert key={i} color={"warning"}>{e} {(e||"").toString().indexOf("confirm")>=0&&<Link to={"/reenviar"}>Reenviar correo de confirmación</Link>}</Alert>)}
                <FormGroup row>
                    <Label for="Mail" sm={3}>{"Correo"}</Label>
                    <Col sm={9}>
                        <Input bsSize="sm" type="text" name="Mail" />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="Password" sm={3}>{"Contraseña"}</Label>
                    <Col sm={9}>
                        <Input bsSize="sm" type="password" name="Password" />
                    </Col>
                </FormGroup>
                <Row>
                    <Col sm={12} className="text-right">
                        <Link to={"/registrate"}>{"Regístrate"}</Link>&nbsp;&nbsp;&nbsp;
                        {/* <Button onClick={ e => {e.preventDefault(); this.toggleModalRegistro();} } color="link">Regístrate</Button> */}
                        {/* <Button onClick={this.toggleModalRegistro} color="link">Regístrate</Button> */}
                        <Link to={"/recuperar"}>Olvidé mi contraseña</Link>&nbsp;&nbsp;&nbsp;
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className="text-right">
                        <br/>
                        <Button disabled={this.state.cargando} color="primary">{"Ingresar"}</Button>
                    </Col>
                </Row>
            </Form>
            <Modal size={"lg"} scrollable wrapClassName="asyntec-portal-clientes" isOpen={this.state.abreModalRegistro} toggle={this.toggleModalRegistro}>
                <ModalBody>
                    <Registro FuncionCerrar={this.toggleModalRegistro}/>
                </ModalBody>
            </Modal>
            </div>
        )
    }
}