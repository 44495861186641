import React, { Component } from 'react';
import { Alert, Spinner, Card, CardHeader, CardBody, FormFeedback, NavItem, NavLink, Nav, Form, FormGroup, Label, Row, Col, Input, Button } from 'reactstrap';
import _ from 'underscore';
import serialize from 'form-serialize';
import { withRouter } from 'react-router';
import API from '../API';
import { Link } from 'react-router-dom';
import { Global } from '~/App'
import ReCAPTCHA from "react-google-recaptcha";

class Recuperar extends Component {
    static displayName = Recuperar.name;

    constructor(props) {
        super(props);
        this.state = {
            errores: [],
            captcha: null,
            cargando: false,
            password: "",
            password2: "",
            correoValido: false
        };
    }
    validarDatos(form) {
        var errores = [];
        if (!form.Mail) {
            errores.push("Debes indicar Correo");
        } 
        if(!form.Captcha){
            errores.push("Captcha invalido");
        }
        this.setState({ errores });
        return _.isEmpty(errores);
    }
    Recuperar(event) {
        event.preventDefault();
        var form = serialize(event.target, { hash: true });
        form.Captcha = this.state.captcha;
        if (this.validarDatos(form)) {
            this.setState({ cargando: true })
            API.Call("Home/ForgotPassword", form)
                .then(response => {
                    this.setState({ success: response.mensaje, cargando: false, captcha: null })
                })
                .catch(error => {
                    this.setState({ errores: [error], cargando: false, captcha: null })
                })
        }
    }
    ActualizaContrasena(event) {
        event.preventDefault();
        var form = serialize(event.target, { hash: true });
        this.setState({ cargando: true })
        API.Call("Home/ResetPassword", form)
            .then(response => {
                this.setState({ cargando: false })
                this.props.history.push({
                    pathname: "/sucursal",
                    state: { mensajes: ["Contraseña actualizada correctamente"] }
                })
            })
            .catch(error => {
                this.setState({ errores: [error], cargando: false })
            })
    }
    render() {
        return (
            <Card>
                <CardHeader className="titulo-modal">Restablecer Contraseña</CardHeader>
                <CardBody className="cuerpo-modal">
                    {this.props.location.state && this.props.location.state.usuario && this.props.location.state.token ?
                        <Row>
                            <Col className="bordeCompleto" sm={{ size: 8, offset: 2 }} >
                                <h4 className="text-muted"><small>Ingrese su nueva contraseña</small></h4>
                                <br />
                                <Form onSubmit={this.ActualizaContrasena.bind(this)}>
                                    {_.map(this.state.errores, (e, i) => <Alert key={i} color={"warning"}>{e}</Alert>)}
                                    <FormGroup row>
                                        <Label for="Password" sm={3}>{"Contraseña"}</Label>
                                        <Col sm={9}>
                                            <Input invalid={this.state.password.length < 8} onChange={e => this.setState({ password: e.target.value })} bsSize="sm" type="password" name="Password" />
                                            <FormFeedback>La contraseña debe tener al menos 8 caracteres</FormFeedback>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="ConfirmPassword" sm={3}>{"Confirma Contraseña"}</Label>
                                        <Col sm={9}>
                                            <Input invalid={this.state.password != this.state.password2} onChange={e => this.setState({ password2: e.target.value })} bsSize="sm" type="password" name="ConfirmPassword" />
                                            <FormFeedback>Las contraseñas deben coincidir</FormFeedback>
                                        </Col>
                                    </FormGroup>
                                    <input type="hidden" name="UserId" value={this.props.location.state.usuario} />
                                    <input type="hidden" name="TokenValidacion" value={this.props.location.state.token} />
                                    <Button className="float-right" color="primary" disabled={this.state.cargando || this.state.password != this.state.password2} type="submit">Restablecer</Button>
                                </Form>
                            </Col>
                        </Row>
                        : this.state.cargando ?
                            <div>
                                <Spinner animation="border" role="status">{"Cargando..."}</Spinner>
                            </div>
                            : this.state.success ?
                                <div>
                                    <Row>
                                        <h4>{this.state.success}</h4>
                                    </Row>
                                </div> :
                                <Row>
                                    <Col className="bordeCompleto" sm={{ size: 8, offset: 2 }} >
                                        <h4 className="text-muted"><small>No recuerda su contraseña?</small></h4>
                                        <h5 className="text-muted"><small>Ingrese su correo y le enviaremos un enlace para restablecerla.</small></h5>
                                        <div className="text-sm-right">
                                            <Link to={"/"}>{"Ya tienes cuenta? Inicia Sesión"}</Link>
                                        </div>
                                        <br />
                                        <Form className="text-sm-right" onSubmit={this.Recuperar.bind(this)}>
                                            {_.map(this.state.errores, (e, i) => <Alert key={i} color={"warning"}>{e} {e.includes("confirm")&&<Link to={"/reenviar"}>Reenviar correo de confirmación</Link>}</Alert>)}
                                            <FormGroup row>
                                                <Label for="Mail" sm={3}>{"Correo"}</Label>
                                                <Col sm={9}>
                                                    <Input invalid={!this.state.correoValido} onChange={e => this.setState({ correoValido: Global.ValidateEmail(e.target.value) })} bsSize="sm" type="text" name="Mail" />
                                                    <FormFeedback>Debe ser un correo válido</FormFeedback>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col className="float-right">
                                                    <ReCAPTCHA
                                                    className="float-right"
                                                    sitekey={Global.apiKeyCaptcha}
                                                    onChange={value => this.setState({ captcha: value })}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <Button className="float-right" color="primary" disabled={!this.state.correoValido || !this.state.captcha} type="submit">{"Recuperar"}</Button>
                                        </Form>
                                    </Col>
                                </Row>}
                </CardBody>
            </Card>
        );
    }
}

export default withRouter(Recuperar);