import React, { Component } from 'react';
import { Spinner, Modal, ModalHeader, InputGroup, InputGroupAddon, UncontrolledTooltip, ModalBody, Card, CardBody, CardHeader, ModalFooter, TabContent, Alert, TabPane, NavItem, NavLink, Nav, NavbarToggler, Form, FormGroup, Label, Row, Col, Input, Button, Navbar, Collapse, Jumbotron } from 'reactstrap';
import { withRouter } from 'react-router';
import _ from 'underscore';
import { Global } from '~/App'
import moment from 'moment';
import API from '~/API';
import { Link } from 'react-router-dom';
import IngresaCuenta from '~/components/IngresaCuenta'
import IngresaLectura from '~/IngresaLectura/Index'
import Login from '~/Usuario/Login'
import ModalImagen from '~/components/ModalImagen'
import ModalPorQue from '~/components/ModalPorQue'
import ModalConvenio from '~/components/ModalConvenio'
import Toggle from 'react-toggle'

class Administra extends Component {
    constructor(props) {
        super();
        this.state = {
            abreAgregarCuenta: false,
            abreEliminarCuenta: {},
            ingresarLectura: {},
            cargando: false,
            empresa: Global.empresaDefecto,
            mensajes: [],
            servicio: "",
            masivo: []
        }
        this.ocupado = false
        this.lock = new AsyncLock()
        this.toggleModalAgregar = this.toggleModalAgregar.bind(this)
        this.toggleModalEliminar = this.toggleModalEliminar.bind(this)
        this.toggleModalIngresarLectura = this.toggleModalIngresarLectura.bind(this);
        this.agregaServicio = this.agregaServicio.bind(this);
        this.procesaServicios = this.procesaServicios.bind(this)

        try {
            this.inIframe = window.self !== window.top
        } catch {
            this.inIframe = true;
        }
        this.calculaRecaudadores = this.calculaRecaudadores.bind(this)

        this.promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve))
    }

    componentWillMount() {
        if (this.props.Usuario) {
            this.procesaServicios(this.props.Servicios)
        }
    }
    componentWillReceiveProps(props) {
        if (props.Usuario && !this.props.Usuario && !_.isEmpty(props.location.state)) { //Borramos los mensajes que pudieran haber en el login
            this.props.history.replace({
                pathname: this.props.location.pathname,
                state: {}
            });
        }
        if (!this.ocupado && props.Usuario && props.Servicios.length != this.props.Servicios.length) {
            this.procesaServicios(props.Servicios)
        }
    }
    componentDidUpdate(prevProps, prevState){
        if(!_.isEqual(prevState.masivo,this.state.masivo)){
            this.calculaRecaudadores(this.state.masivo,true)
        }
    }
    procesaServicios(Servicios) {
        this.ocupado = true;
        Servicios.map((e, i) => this.BuscaServicio(e.idServicio, e.empresa, i))
        this.ocupado = false;
    }
    async BuscaServicio(servicio, empresa, i) {
        var e = { error: "ERROR" }
        try {
            e = await API.Call(`Consulta/${empresa}/Servicio/${servicio}`);
        } catch { }
        var p = { error: "ERROR" }
        var puedeConvenio = false;
        try {
            p = await API.Call(`Consulta/${empresa}/Puede/Lectura/`, { IdServicio: servicio, Fecha: moment() });
        } catch { }
        try {
            if (e.deuda_actual) {
                puedeConvenio = (await API.Call(`Consulta/${empresa}/Puede/Convenio`, { IdServicio: servicio }));
            }
        } catch { }
        this.lock.enable()
        var s = [...this.props.Servicios];
        s[i] = { ...s[i], ...e, puedeConvenio }
        if (p.ok) {
            s[i].IdProcesoLectura = p.proceso;
        }
        Global.setServicios(s);
        var masivo = s.filter(e => e.deuda_actual > 0).sort((a,b)=>Global.empresas.some(o => o.key == b.empresa) - Global.empresas.some(o => o.key == a.empresa)).map(e => ({ ...e, Empresa: e.Empresa ? e.Empresa : (Global.empresas.find(o => o.key == e.empresa)||{}) }))
        if(masivo.length) await this.promisedSetState({ masivo: this.buscaCuentasSimilares(masivo, masivo[0]) })
        this.lock.disable()
    }
    buscaCuentasSimilares(masivo, item) {
        if(!item) return []
        if(!item.Empresa) item.Empresa = Global.empresas.find(e => e.key == item.empresa) || {}
        var recs = masivo.length ? Object.entries({ ...item.Empresa.recaudadores }) : {}
        return masivo.filter(e => {
            var n = Object.entries({ ...e.Empresa.recaudadores })
            return recs.some(r => n.some(d => r[0] == d[0] && r[1] == d[1]))
        })
    }
    toggleModalAgregar() {
        this.setState({ abreAgregarCuenta: !this.state.abreAgregarCuenta, mensajes: this.state.abreAgregarCuenta ? null : this.state.mensajes })
    }
    toggleModalEliminar(abreEliminarCuenta = {}) {
        this.setState({ abreEliminarCuenta, errorEliminar: abreEliminarCuenta ? null : this.state.errorEliminar })
    }
    toggleModalIngresarLectura(servicio = null, empresa = null, proceso = null) {
        if (!servicio || !empresa || !proceso) {
            this.setState({ ingresarLectura: { abreModal: false } });
        } else {
            this.setState({ ingresarLectura: { abreModal: true, servicio, empresa, proceso } });
        }
    }
    calculaRecaudadores(entrada, actualiza = false) {
        var defecto = undefined
        var ret = entrada.reduce((m, o) => {
            if (!m) return m
            if(!o.Empresa) o.Empresa = Global.empresas.find(e => e.key == o.empresa) || {}
            if(defecto === undefined) defecto = o.Empresa?.otros?.RecaudadorDefecto
            else defecto = defecto == o.Empresa?.otros?.RecaudadorDefecto ? defecto : null
            var recs = Object.entries(m)
            if (!recs.length) m = { ...o.Empresa.recaudadores }
            else {
                var n = Object.entries({ ...o.Empresa.recaudadores })
                for (var f in recs) {
                    var r = recs[f]
                    if (!n.some(d => r[0] == d[0] && r[1] == d[1]))
                        delete (m[r[0]])
                }
                if (_.isEmpty(m)) return null // muere, no hay nada
            }
            return m
        }, {})
        if (actualiza) {
            var keys = Object.keys(ret || {});
            if(keys.some(e => e == defecto)){
                this.setState({ formaPago: defecto })
            }else if (keys.length == 1) {
                if (this.state.formaPago != keys[0]) this.setState({ formaPago: keys[0] })
            } else {
                if (this.state.formaPago) this.setState({ formaPago: undefined })
            }
        }
        return ret;
    }
    agregaServicio(e) {
        {
            e.preventDefault();
            this.setState({ cargando: true })
            let idServicio = e.target.servicio.value;
            let documento = ((e.target.documento || {}).value) || "";
            var mensajes = [];
            var valido = true;
            if (idServicio > 2147483647) {
                valido = false;
                mensajes.push({ tipo: "warning", texto: "Número de cuenta muy largo" });
            }
            if (idServicio === "") {
                valido = false;
                mensajes.push({ tipo: "warning", texto: "Debes indicar número de cuenta" });
            }
            if (documento > 9223372036854775807) {
                valido = false;
                mensajes.push({ tipo: "warning", texto: "Número de documento muy largo" });
            }
            if (!Global.csd && documento === "") {
                valido = false;
                mensajes.push({ tipo: "warning", texto: "Debes indicar número de documento" });
            }
            let empresa = e.target.empresa.value;
            if (valido) {
                API.Call(`Administra/${empresa}/Servicio/Agregar`, { IdServicio: idServicio, Documento: documento })
                    .then(() => {
                        var Servicios = [...this.props.Servicios];
                        Servicios.push({ idServicio, empresa, nombreEmpresa: Global.empresas.find(o => o.key == empresa).nombre })
                        Global.setServicios(Servicios)
                        this.setState({ cargando: false, mensajes })
                        if (this.state.abreAgregarCuenta) {
                            this.toggleModalAgregar();
                        }
                    }).catch(errorAgregar => this.setState({ cargando: false, mensajes: [{ tipo: "danger", texto: errorAgregar }] }))
            } else {
                this.setState({ cargando: false, mensajes });
            }
        }
    }
    render() {
        var self = this;
        var agregaCuenta = (
            <div>
                {Global.empresas.length > 1 ?
                    <Row>
                        <Label for="empresa" sm={6} className="text-sm-right">Empresa</Label>
                        <Col sm={6}>
                            <Input bsSize="sm" type="select" name="empresa" value={this.state.empresa} onChange={e => this.setState({ empresa: e.target.value })}>
                                {
                                    Global.empresas.map((e, i) => <option key={i} value={e.key}>{e.nombre}</option>)
                                }
                            </Input>
                        </Col>
                    </Row>
                    : <Input type="hidden" name="empresa" value={this.state.empresa} />}
                <Row className="text-right mb-2">
                    <Col sm={12}>
                        <ModalPorQue />
                        <ModalImagen empresa={this.state.empresa} />
                    </Col>
                </Row>
                <FormGroup row>
                    <Label for="servicio" className="text-sm-right numero-cuenta" sm={6}><span>Número de Cuenta</span></Label>
                    <Col sm={6}>
                        <InputGroup>
                            <Input className="text-right" bsSize="sm" onChange={e => this.setState({ servicio: e.target.value })} type="number" name="servicio" />
                            <InputGroupAddon id="asyntecPortalDVServicio" addonType="append">
                                <Button type="button" size="sm" color="link" disabled>{this.state.servicio ? <div> - {Global.DV(this.state.servicio)}</div> : <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}</Button>
                                <UncontrolledTooltip placement="top" target="asyntecPortalDVServicio">
                                    Dígito Verificador
                                </UncontrolledTooltip>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </FormGroup>
                {!Global.csd &&
                    <FormGroup row>
                        <Label for="documento" className="text-sm-right" sm={6}><span>Número de Documento</span></Label>
                        <Col sm={6}>
                            <Input bsSize="sm" type="number" name="documento" />
                        </Col>
                    </FormGroup>}
                {_.map(this.state.mensajes, (m, i) => <Alert key={i} color={m.tipo}>
                    {m.texto}
                </Alert>)
                }
            </div>
        )
        var recaudadores = this.calculaRecaudadores(this.state.masivo) || {}


        var MapFila = array => array.map(ser => {
            var servicio = { ...ser.e, Empresa: ser.e.Empresa ? ser.e.Empresa : Global.empresas.find(o => o.key == ser.e.empresa) || {} }
            var i = ser.i
            return (
                <Row key={i} style={{ marginBottom: "15px" }}>
                    <Col lg={{ size: 6, offset: 2 }} md={9} sm={9} style={{ marginBottom: 0 }} className="bordeIzquierdoSimple">
                        <div><small>{servicio.nombreEmpresa}</small></div>
                        <Link className="text-underlined" to={{
                            pathname: "/detalle",
                            state: { servicio: servicio }
                        }}>
                            <h4 className="m-0 d-inline"><small>{Global.AgregaDV(servicio.idServicio)}</small></h4>
                        </Link>
                        {servicio.dir_instal && <Row>
                            <Col sm={12}>{Global.toTitleCase(servicio.dir_instal)}, {Global.toTitleCase(servicio.comuna_instal)}</Col>
                        </Row>}
                        {servicio.nom_cliente && <Row>
                            <Col sm={12}>{Global.toTitleCase(servicio.nom_cliente)}</Col>
                        </Row>}
                        <Row>
                            <Col sm={3}>
                                <small>
                                    <Link to={{ pathname: "/detalle", state: { servicio } }}>Ver detalles</Link>
                                </small>
                            </Col>
                            {servicio.e_mail !== undefined && !servicio.e_mail && servicio.Empresa?.otros?.BotonEmail !== undefined ? <Col sm={3}>
                                <small>
                                    <Link className="boton-email-detalle-servicio" to={{ pathname: "/detalle/email", state: { servicio } }}>Solicitar Documentos por E-Mail</Link>
                                </small>
                            </Col> : null}
                            {servicio.IdProcesoLectura ?
                                <Col sm={3}>
                                    <small>
                                        <a href="#" onClick={o => {
                                            o.preventDefault();
                                            this.toggleModalIngresarLectura(servicio.idServicio, servicio.empresa, servicio.IdProcesoLectura);
                                        }}>Ingresar Lectura</a>
                                    </small>
                                </Col>
                                : ""}
                            {servicio.puedeConvenio ? <Col sm={3}><ModalConvenio Servicio={servicio} ActualizarPadre={() => { this.BuscaServicio(servicio.idServicio, servicio.empresa, i) }} /></Col> : ""}
                            <Col sm={3}>
                                <small>
                                    <a href="#" onClick={o => {
                                        o.preventDefault();
                                        this.toggleModalEliminar({ servicio: servicio.idServicio, empresa: servicio.empresa })
                                    }}>Desvincular cuenta</a>
                                </small>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={{ size: 2 }} md={3} sm={3} style={{ marginBottom: 0 }} className="bordeDerecho text-center">
                        {servicio.deuda_actual !== undefined ?
                            <div>
                                <small>Deuda {servicio.deuda_vencida > 0 ? "Vencida" : "Actual"}</small>
                                <h4>$ {Global.FormatearNumero(servicio.deuda_actual)} <small>CLP</small></h4>
                                <br />
                                {servicio.deuda_actual > 0 ? <Row>
                                    <Col>
                                        <Button tag={Link} to={{
                                            pathname: "/paga",
                                            state: { servicio: servicio.idServicio, empresa: servicio.empresa }
                                        }} color={servicio.deuda_vencida > 0 ? "danger" : "primary"}>Pagar</Button>
                                    </Col><Col>
                                        <Toggle
                                            checked={this.state.masivo.some(e => e.idServicio == servicio.idServicio && e.empresa == servicio.empresa)}
                                            onClick={e => {
                                                var masivo = this.state.masivo
                                                if (masivo.some(e => e.idServicio == servicio.idServicio && e.empresa == servicio.empresa)) {
                                                    // sacamos
                                                    masivo = masivo.filter(e => !(e.idServicio == servicio.idServicio && e.empresa == servicio.empresa))
                                                } else {
                                                    if (!this.calculaRecaudadores([...masivo, servicio], false)) {
                                                        if (window.confirm("Deberá quitar las otras cuentas ¿Está seguro?")) {
                                                            masivo = this.buscaCuentasSimilares(pendientes, servicio)
                                                        }
                                                    } else {
                                                        masivo = (!masivo.length) ? this.buscaCuentasSimilares(pendientes, servicio) : [...masivo, servicio]
                                                    }
                                                }
                                                this.setState({ masivo })
                                            }}
                                        />
                                        <Label>
                                            {this.state.masivo[servicio.idServicio] ? "Quitar del pago" : "Agregar al pago"}
                                        </Label>
                                    </Col>
                                </Row>
                                    : null}
                            </div>
                            : servicio.error ? <div><h4>⚠️</h4><small>No se encontró información</small></div>
                                : <Spinner animation="border" role="status">Cargando...</Spinner>}
                    </Col>
                </Row>
            )
        })

        var pendientes = this.props.Servicios.filter(e => e.deuda_actual).map(e => ({ ...e, Empresa: e.Empresa ? e.Empresa : Global.empresas.find(o => o.key == e.empresa) }))

        var sinPagar = pendientes.filter(e => !this.state.masivo.some(m => e.empresa == m.empresa && e.idServicio == m.idServicio))

        return (
            <div>
                {!this.props.Usuario ?
                    <Card>
                        <CardHeader className="titulo-modal">Sucursal Virtual</CardHeader>
                        <CardBody className="cuerpo-modal">
                            <Row>
                                <Col sm={{ size: 6, offset: 3 }} className="bordeCompleto">
                                    <h4 className="text-muted"><small>Ingrese con su usuario y contraseña</small></h4>
                                    <br />
                                    {this.props.location.state && _.map(this.props.location.state.mensajes, (e, i) => <Alert key={i} color={"warning"}>{e}</Alert>)}
                                    <Login />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    :
                    <Card>
                        <CardHeader className="titulo-modal">{this.props.Servicios.length ? "Mis Cuentas" : "Sucursal Virtual"}</CardHeader>
                        <CardBody className="cuerpo-modal">
                            {this.props.cargandoServicios ?
                                <Spinner animation="border" role="status">{"Cargando..."}</Spinner>
                                :
                                <Row>
                                    <Col sm={12}>
                                        <Row>
                                            <Col sm={12}>
                                                <h1>Sucursal Virtual de {this.props.Usuario.nombre}</h1>
                                                <br />
                                            </Col>
                                        </Row>
                                        {
                                            this.state.masivo.length ? <Form method="post" target={this.inIframe ? "_blank" : null} action={`${Global.UrlApi}/Recaudacion/${this.state.masivo[0].empresa}/OrdenDePago/${this.state.formaPago}/Generar/Masivo`}>
                                                <Row>
                                                    <Col lg={{ size: 8, offset: 2 }} md={9} sm={9} style={{ marginBottom: "1rem" }} >
                                                        <h3 className='pagoTotalHeader' style={{ marginBottom: "1rem" }}>Pago fácil</h3>
                                                        <Row className="bordeCompleto text-center">
                                                            <Col sm={9}>
                                                                <Row>
                                                                    <Col sm={1}><strong></strong></Col>
                                                                    <Col sm={2}><strong>Cuenta</strong></Col>
                                                                    <Col sm={3}><strong>Dirección</strong></Col>
                                                                    <Col sm={3}><strong>Cliente</strong></Col>
                                                                    <Col sm={3}><strong>Total</strong></Col>
                                                                </Row>
                                                                {this.state.masivo.sort((a, b) => a.empresa.localeCompare(b.empresa)).map(e => {
                                                                    e.Empresa = e.Empresa ? e.Empresa : Global.empresas.find(o => o.key == e.empresa) || {}
                                                                    return <Row>
                                                                        <Col sm={1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                            <Button onClick={ev => {
                                                                                ev.preventDefault();
                                                                                this.setState({ masivo: this.state.masivo.filter(d => !(d.idServicio == e.idServicio && e.empresa == d.empresa)) })
                                                                            }} size="sm" color="secondary"><span aria-hidden="true">×</span></Button>
                                                                        </Col>
                                                                        <Col sm={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><span><span>{e.Empresa.nombre}</span><br />{Global.AgregaDV(e.idServicio)}</span></Col>
                                                                        <Col sm={3} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{Global.toTitleCase(e.dir_instal)}, {Global.toTitleCase(e.comuna_instal)}</Col>
                                                                        <Col sm={3} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{Global.toTitleCase(e.nom_cliente)}</Col>
                                                                        <Col sm={3} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><span><strong>$ {Global.FormatearNumero(e.deuda_actual)} <small>CLP</small></strong></span></Col>
                                                                    </Row>
                                                                })}
                                                            </Col>
                                                            <Col sm={3} style={{ display: "flex", justifyContent: "center", alignItems: "end" }}>
                                                                <div>
                                                                    <h6>Total a Pagar</h6>
                                                                    <h4>$ {Global.FormatearNumero(this.state.masivo.reduce((m, s) => m + s.deuda_actual, 0))} <small>CLP</small></h4>
                                                                    <br />
                                                                    <h6>Forma de Pago</h6>
                                                                    <div className="text-center">
                                                                        {recaudadores.transbank && 
                                                                            <FormGroup className="transbank" check inline>
                                                                                <Label check>
                                                                                    <Input type="radio" checked={this.state.formaPago == "transbank"} onClick={() => this.setState({ formaPago: "transbank" })} />{' '}
                                                                                    <img src="https://cdn.smartx.cl/images/logo-webpay-plus.png" width="60" />
                                                                                </Label>
                                                                            </FormGroup>}
                                                                        {recaudadores.flow && 
                                                                            <FormGroup className="flow" check inline>
                                                                                <Label check>
                                                                                    <Input type="radio" checked={this.state.formaPago == "flow"} onClick={() => this.setState({ formaPago: "flow" })} />{' '}
                                                                                    <img src="https://recaudador.asyntec.com/logoflow.png" width="60" />
                                                                                </Label>
                                                                            </FormGroup>}
                                                                        {recaudadores.khipu && 
                                                                            <FormGroup className="khipu" check inline>
                                                                                <Label check>
                                                                                    <Input type="radio" checked={this.state.formaPago == "khipu"} onClick={() => this.setState({ formaPago: "khipu" })} />{' '}
                                                                                    <img src="https://s3.amazonaws.com/static.khipu.com/buttons/2015/110x50-transparent.png" width="80" />
                                                                                </Label>
                                                                            </FormGroup>}
                                                                        {recaudadores.servipag && 
                                                                            <FormGroup className="servipag" check inline>
                                                                                <Label check>
                                                                                    <Input type="radio" checked={this.state.formaPago == "servipag"} onClick={() => this.setState({ formaPago: "servipag" })} />{' '}
                                                                                    <img src="https://cdn.smartx.cl/images/logo-servipag.png" width="60" />
                                                                                </Label>
                                                                            </FormGroup>}
                                                                        {recaudadores.unired && 
                                                                            <FormGroup className="unired" check inline>
                                                                                <Label check>
                                                                                    <Input type="radio" checked={this.state.formaPago == "unired"} onClick={() => this.setState({ formaPago: "unired" })} />{' '}
                                                                                    <img src="https://recaudador.asyntec.com/unired.png" width="60" />
                                                                                </Label>
                                                                            </FormGroup>}
                                                                    </div>
                                                                    <br />
                                                                    <small>Pagando {pendientes.length - sinPagar.length} cuenta{(pendientes.length - sinPagar.length) > 1 ? "s" : ""} de {pendientes.length} pendiente{pendientes.length > 1 ? "s" : ""}</small>
                                                                    <p>
                                                                        {API.Cookie && <input type="hidden" name="token" value={API.Cookie} />}
                                                                        <input type="hidden" name="json" value={JSON.stringify(this.state.masivo.map(e => ({ monto: e.deuda_actual, id_servicio: e.idServicio, noDoc: e.no_doc, empresa: e.empresa })))} />
                                                                        <Button style={{ marginTop: "2rem", float: "right" }} disabled={!this.state.formaPago || this.props.Servicios.some(e =>e.puedeConvenio === undefined)} color="primary" >Pagar</Button>
                                                                    </p>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <h3 className='resumenCuentasHeader'>Resumen de cuentas</h3>
                                                    </Col>
                                                </Row>
                                            </Form> : null
                                        }
                                        {this.props.Servicios.length ?
                                            <Row>
                                                <Col sm={12}>
                                                    {MapFila(this.props.Servicios.map((e, i) => ({ e, i })).filter(e => e.e.deuda_actual).sort((a, b) => a.e.empresa.localeCompare(b.e.empresa)))}
                                                    {MapFila(this.props.Servicios.map((e, i) => ({ e, i })).filter(e => !e.e.deuda_actual).sort((a, b) => a.e.empresa.localeCompare(b.e.empresa)))}
                                                    <Row>
                                                        <Col sm={{ size: 8, offset: 2 }} className={this.props.Servicios.length ? "text-right" : "text-center"}>
                                                            <hr />
                                                            <Button color="primary" size="lg" onClick={this.toggleModalAgregar}>Agregar Cuenta</Button>{' '}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            :
                                            <Row>
                                                <Col sm={6}>
                                                    <h4 className="text-muted"><small>En la Sucursal Virtual puedes:</small></h4>
                                                    <br />
                                                    <ul className="list-unstyled lead">
                                                        <li>- Revisar tus cuentas en un sólo lugar</li>
                                                        <li>- Ver los documentos históricos, pagos y consumos</li>
                                                        <li>- Pagar tus cuentas sin tener un documento a la vista</li>
                                                        <li>- Informar tus consumos</li>
                                                    </ul>
                                                </Col>
                                                <Col sm={6} className="bordeCompleto">
                                                    <h4 className="text-muted"><small>Para empezar, agrega tus Cuentas</small></h4>
                                                    <br />
                                                    <Form onSubmit={this.agregaServicio}>
                                                        {agregaCuenta}
                                                        <div className="text-right">
                                                            <Button type="submit" disabled={this.state.cargando} color="primary">Agregar</Button>
                                                        </div>
                                                    </Form>
                                                </Col>
                                            </Row>}
                                        <Modal wrapClassName="asyntec-portal-clientes" isOpen={this.state.abreAgregarCuenta} toggle={this.toggleModalAgregar}>
                                            <Form onSubmit={this.agregaServicio}>
                                                <ModalHeader toggle={this.toggleModalAgregar}>Agregar una Cuenta</ModalHeader>
                                                <ModalBody>
                                                    {agregaCuenta}
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button color="secondary" disabled={this.state.cargando} onClick={e => { e.preventDefault(); this.toggleModalAgregar() }}>Cancelar</Button>
                                                    <Button type="submit" disabled={this.state.cargando} color="primary">Agregar</Button>
                                                </ModalFooter>
                                            </Form>
                                        </Modal>
                                        <Modal wrapClassName="asyntec-portal-clientes" isOpen={this.state.abreEliminarCuenta.servicio} toggle={this.toggleModalEliminar}>
                                            <Form onSubmit={e => {
                                                e.preventDefault();
                                                this.setState({ cargando: true })
                                                API.Call(`Administra/${this.state.abreEliminarCuenta.empresa}/Servicio/Eliminar`, { IdServicio: this.state.abreEliminarCuenta.servicio })
                                                    .then(() => {
                                                        this.setState({ cargando: false })
                                                        Global.setServicios(this.props.Servicios.filter(e => e.idServicio != this.state.abreEliminarCuenta.servicio || e.empresa != this.state.abreEliminarCuenta.empresa))
                                                        this.toggleModalEliminar();
                                                    }).catch(errorEliminar => this.setState({ errorEliminar, cargando: false }))
                                            }
                                            }>
                                                <ModalHeader toggle={this.toggleModalEliminar}>Desvincular una Cuenta</ModalHeader>
                                                <ModalBody>
                                                    <h4 className="text-muted"><small>¿Está seguro que desea desvincular la Cuenta {Global.AgregaDV(this.state.abreEliminarCuenta.servicio)} a su usuario?</small></h4>
                                                    {this.state.errorEliminar && <Alert color="warning">
                                                        {this.state.errorEliminar}
                                                    </Alert>}
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button color="secondary" disabled={this.state.cargando} onClick={e => { e.preventDefault(); this.toggleModalEliminar() }}>Cancelar</Button>
                                                    <Button type="submit" disabled={this.state.cargando} color="danger">Eliminar</Button>{' '}
                                                </ModalFooter>
                                            </Form>
                                        </Modal>
                                        <Modal size="lg" wrapClassName="asyntec-portal-clientes" isOpen={this.state.ingresarLectura.abreModal} toggle={this.toggleModalIngresarLectura}>
                                            <ModalBody>
                                                <IngresaLectura
                                                    IngresarLectura={this.state.ingresarLectura}
                                                    Usuario={this.props.Usuario}
                                                    FuncionCerrar={this.toggleModalIngresarLectura}
                                                    FuncionOk={() => {
                                                        var servicios = self.props.Servicios;
                                                        var servicio = _.findWhere(servicios, { idServicio: self.state.ingresarLectura.servicio });
                                                        servicio.IdProcesoLectura = null;
                                                        Global.setServicios(servicios);
                                                    }}
                                                />
                                            </ModalBody>
                                        </Modal>
                                    </Col>
                                </Row>}
                        </CardBody>
                    </Card>}
            </div>
        )
    }
}

class AsyncLock {
    constructor() {
        this.disable = () => { }
        this.promise = Promise.resolve()
    }

    enable() {
        this.promise = new Promise(resolve => this.disable = resolve)
    }
}


export default withRouter(Administra);