import './bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import API from './API';
import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

const rootElement = document.getElementById('Portal-Clientes');
rootElement.classList.add("asyntec-portal-clientes")
API.UrlBase = rootElement.getAttribute("UrlApi");

API.Call(`Consulta/Parametros`)
.then(async response => {
  await API.RevisarCookie();

var ParametrosPortalClientes = {
    UrlApi: API.UrlBase,
    Key: rootElement.getAttribute("Key"),
    ...(response)
  };

  ReactDOM.render(
    <BrowserRouter basename={window.location.pathname+'#'}>
      <App portal = {ParametrosPortalClientes}/>
    </BrowserRouter>,
    rootElement);
});