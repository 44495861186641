import React, { Component } from 'react';
import { Collapse, Container, Navbar, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Global } from '~/App';
import API from '~/API';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);
  }

  Logout(e) {
    e.preventDefault();
    API.BorrarCookie();
    Global.setServicios([]);
    Global.setUsuario(null);
  }

  render() {
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container>
            {!this.props.Usuario ?
              <Collapse className="d-sm-inline-flex" isOpen navbar>
                <ul className="navbar-nav flex-grow">
                  <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/sucursal">Sucursal Virtual</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} className="text-dark paga-tu-cuenta" to="/paga"><span>Paga tu Cuenta</span></NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/lectura">Ingresa Lectura</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/registrate">Registrate</NavLink>
                  </NavItem>
                </ul>
              </Collapse>
              : <>
                <Collapse className="d-sm-inline-flex" isOpen navbar>
                  <ul className="navbar-nav flex-grow">
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to="/sucursal">{this.props.Servicios.length ? "Mis Cuentas" : "Sucursal Virtual"}</NavLink>
                    </NavItem>
                    {
                      !this.props.Servicios.length ?
                        null :
                        this.props.Servicios.length === 1 ?
                          <NavItem>
                            <NavLink tag={Link} className="text-dark" to={{
                              pathname: "/detalle",
                              state: { servicio: this.props.Servicios[0] }
                            }}>Detalle de Cuenta</NavLink>
                          </NavItem>
                          : <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                              Detalle de Cuenta
                </DropdownToggle>
                            <DropdownMenu>
                              {this.props.Servicios.length ? this.props.Servicios.map((e, i) => (
                                <DropdownItem key={i} tag={Link} to={{
                                  pathname: "/detalle",
                                  state: { servicio: e }
                                }}>
                                  {Global.AgregaDV(e.idServicio)}<br />
                                  {e.dir_instal && <small>{Global.toTitleCase(e.dir_instal)}<br /></small>}
                                  <small>{e.nombreEmpresa}</small>
                                </DropdownItem>
                              )) : <DropdownItem tag={Link} to="/sucursal">
                                  Agrega una cuenta
                    </DropdownItem>}
                            </DropdownMenu>
                          </UncontrolledDropdown>}
                    {/* <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/postular">Postular a convenio</NavLink>
                </NavItem> */}
                  </ul>
                </Collapse>
                <NavLink tag={Link} className="text-dark" to="/usuario">{`${this.props.Usuario.nombre} ${this.props.Usuario.apellido}`}</NavLink>
                <NavLink className="text-dark" href="#" onClick={this.Logout}>Salir</NavLink>
              </>}
          </Container>
        </Navbar>
      </header>
    );
  }
}
