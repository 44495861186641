import React, { Component } from 'react';
import { Alert, Spinner, Card, CardHeader, CardBody, FormFeedback, NavItem, NavLink, Nav, Form, FormGroup, Label, Row, Col, Input, Button } from 'reactstrap';
import _ from 'underscore';
import serialize from 'form-serialize';
import { withRouter } from 'react-router';
import API from '../API';
import { Global } from '~/App';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

class Reenviar extends Component {
    static displayName = Reenviar.name;

    constructor(props) {
        super(props);
        this.state = {
            errores: [],
            captcha: null,
            cargando: false,
            password: "",
            password2: "",
            correoValido: false
        };
    }
    validarDatos(form) {
        var errores = [];
        if (!form.Mail) {
            errores.push("Debes indicar Correo");
        }
        if(!form.Captcha){
            errores.push("Captcha invalido");
        }
        this.setState({ errores });
        return _.isEmpty(errores);
    }
    Reenviar(event) {
        event.preventDefault();
        var form = serialize(event.target, { hash: true });
        form.Captcha = this.state.captcha;
        if (this.validarDatos(form)) {
            this.setState({ cargando: true })
            API.Call("Home/ReenviarMailConfirmar", form)
                .then(response => {
                    this.setState({ success: response.mensaje, cargando: false, captcha: null })
                })
                .catch(error => {
                    this.setState({ errores: [error], cargando: false, captcha: null })
                })
        }
    }
    render() {
        return (
            <Card>
                <CardHeader className="titulo-modal">Reenviar correo de confirmación</CardHeader>
                <CardBody className="cuerpo-modal">
                    {this.state.cargando ?
                            <div>
                                <Spinner animation="border" role="status">{"Cargando..."}</Spinner>
                            </div>
                            : this.state.success ?
                                <div>
                                    <Row>
                                        <h4>{this.state.success}</h4>
                                    </Row>
                                </div> :
                                <Row>
                                    <Col className="bordeCompleto" sm={{ size: 8, offset: 2 }} >
                                        <h4 className="text-muted"><small>No ha podido confirmar su correo?</small></h4>
                                        <h5 className="text-muted"><small>Ingrese su correo y le enviaremos un enlace de confirmación.</small></h5>
                                        <div className="text-sm-right">
                                            <Link to={"/"}>{"Ya tienes cuenta? Inicia Sesión"}</Link>&nbsp;&nbsp;
                                            <Link to={"/recuperar"}>Olvidé mi contraseña</Link>
                                        </div>
                                        <br />
                                        <Form className="text-sm-right" onSubmit={this.Reenviar.bind(this)}>
                                            {_.map(this.state.errores, (e, i) => <Alert key={i} color={"warning"}>{e}</Alert>)}
                                            <FormGroup row>
                                                <Label for="Mail" sm={3}>{"Correo"}</Label>
                                                <Col sm={9}>
                                                    <Input invalid={!this.state.correoValido} onChange={e => this.setState({ correoValido: Global.ValidateEmail(e.target.value) })} bsSize="sm" type="text" name="Mail" />
                                                    <FormFeedback>Debe ser un correo válido</FormFeedback>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col className="float-right">
                                                    <ReCAPTCHA
                                                    className="float-right"
                                                    sitekey={Global.apiKeyCaptcha}
                                                    onChange={value => this.setState({ captcha: value })}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <Button className="float-right" color="primary" disabled={!this.state.correoValido || !this.state.captcha} type="submit">{"Reenviar"}</Button>
                                        </Form>
                                    </Col>
                                </Row>}
                </CardBody>
            </Card>
        );
    }
}

export default withRouter(Reenviar);