import React, { Component } from 'react';
import { TabContent, TabPane, FormFeedback, InputGroupAddon, UncontrolledTooltip, NavItem, Alert, NavLink, Nav, Form, FormGroup, Label, Row, Col, Input, Button, InputGroup } from 'reactstrap';
import { Global } from '~/App';
import _ from 'underscore';
import ModalImagen from '~/components/ModalImagen'
import ModalPorQue from '~/components/ModalPorQue'
import ReCAPTCHA from "react-google-recaptcha";
import API from '~/API';
import Autosuggest from 'react-autosuggest';


export default class IngresaCuenta extends Component {
  constructor() {
    super();
    this.state = {
      active: 1,
      empresa: Global.empresaDefecto,
      mensajes: [],
      servicio: "",
      //busqueda direccion
      captcha: null,
      comuna: "",
      calle: "",
      noCasa: "",
      noDpto: "",
      noCasa2: "",
      calles: [],
      callesSinFiltrar: [],
      comunas: [],
      comunasSinFiltrar: []
    }
  }

  componentWillMount() {
    this.GetCalles();
    this.GetComunas();
  }

  componentWillReceiveProps(props) {
    if (this.state.calles.length == 0 && !this.state.calle)
      this.setState({ calles: this.state.callesSinFiltrar });
    if (this.state.comunas.length == 0 && !this.state.comuna)
      this.setState({ comunas: this.state.comunasSinFiltrar });
  }
  GetCalles() {
    API.Call(`Consulta/${this.state.empresa}/Calles`)
      .then((callesSinFiltrar) => {
        this.setState({ callesSinFiltrar });
      });
  }

  onChangeAddress(event, { newValue }) {
    this.setState({
      calle: newValue
    });
  }
  normalizeText(str){
    return (str||"").toUpperCase().trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9\s]/g, '')
  }
  onSuggestionsFetchRequested({ value }) {
    this.setState({ calles: _.filter(this.state.callesSinFiltrar, (e) => { return this.normalizeText(e.NomCalle).includes(this.normalizeText(value)); }) });
  }

  GetSuggestionValue(suggestion) {
    return suggestion.NomCalle;
  }

  RenderSuggestion(suggestion) {
    return (
      <span>{suggestion.NomCalle}</span>
    );
  }

  DebouncedBuscarCalles({ value, reason }) {
    if (reason == 'type') {
      this.setState({ calles: _.filter(this.state.callesSinFiltrar, (e) => { e.NomCalle.includes(value) }) })
    }
  }
  GetComunas() {
    API.Call(`Consulta/${this.state.empresa}/Comunas`)
      .then((comunasSinFiltrar) => {
        this.setState({ comunasSinFiltrar });
      });
  }
  cambiarEmpresa(e) {
    var self = this;
    this.setState({ empresa: e.target.value }, () => { self.GetComunas(); self.GetCalles(); });
  }
  onChangeComuna(event, { newValue }) {
    this.setState({
      comuna: newValue
    });
  }
  onSuggestionsFetchRequestedComuna({ value }) {
    this.setState({ comunas: _.filter(this.state.comunasSinFiltrar, (e) => { return this.normalizeText(e.NomComuna).includes(this.normalizeText(value)); }) });
  }

  GetSuggestionValueComuna(suggestion) {
    return suggestion.NomComuna;
  }

  RenderSuggestionComuna(suggestion) {
    return (
      <span>{suggestion.NomComuna}</span>
    );
  }

  DebouncedBuscarComuna({ value, reason }) {
    if (reason == 'type') {
      this.setState({ comuna: _.filter(this.state.comunasSinFiltrar, (e) => { e.NomComuna.includes(value) }) })
    }
  }

  render() {
    var selectEmpresa = (Global.empresas.length > 1 &&
      <Row>
        <Label for="empresa" sm={6} className="text-sm-right">Empresa</Label>
        <Col sm={6}>
          <Input bsSize="sm" name="empresa" type="select" value={this.state.empresa} onChange={this.cambiarEmpresa.bind(this)}>
            {
              Global.empresas.map((e, i) => <option key={i} value={e.key} >{e.nombre}</option>)
            }
          </Input>
        </Col>
      </Row>);
    return (
      <div>
        {Global.buscaDireccion &&
          <Nav tabs style={{ marginBottom: "1rem" }}>
            <NavItem>
              <NavLink
                className="numero-cuenta"
                active={this.state.active == 1}
                onClick={() => this.setState({ active: 1 })}>
                  <span>
                Número de Cuenta
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="direccion-domicilio"
                active={this.state.active == 2}
                onClick={() => this.setState({ active: 2 })}>
                  <span>
                Dirección
                </span>
              </NavLink>
            </NavItem>
          </Nav>}
        <TabContent activeTab={this.state.active}>
          <TabPane tabId={1}>
            {selectEmpresa}
            <Form className="text-sm-right" onSubmit={e => {
              e.preventDefault();

              var servicio = e.target.servicio.value;
              var documento = (e.target.documento||{}).value;
              var mensajes = [];
              var valido = true;
              if (servicio === "") {
                mensajes.push({ tipo: "warning", texto: "Debes indicar cuenta" });
                valido = false;
              }
              if (!Global.csd && documento === "") {
                mensajes.push({ tipo: "warning", texto: "Debes indicar documento" });
                valido = false;
              }
              if (servicio > 2147483647) {
                mensajes.push({ tipo: "warning", texto: "Número de cuenta muy grande" });
                valido = false;
              }
              if (documento > 9223372036854775807) {
                mensajes.push({ tipo: "warning", texto: "Número se documento muy grande" });
                valido = false;
              }

              if (valido) {
                this.props.history.push({
                  pathname: '/paga',
                  state: { empresa: this.state.empresa, servicio, documento, captcha: this.state.captcha }
                })
              } else {
              }
              this.setState({ mensajes });
            }}>
              <Row className="mb-2">
                <Col sm={12}>
                  <ModalPorQue />
                  <ModalImagen empresa={this.state.empresa} />
                </Col>
              </Row>
              <FormGroup row>
                <Label for="servicio" className="numero-cuenta" sm={6}><span>Número de Cuenta</span></Label>
                <Col sm={6}>
                  <InputGroup>
                    <Input className="text-right" bsSize="sm" onChange={e => this.setState({ servicio: e.target.value })} type="number" name="servicio" />
                    <InputGroupAddon id="asyntecPortalDVServicio" addonType="append">
                      <Button type="button" size="sm" color="link" disabled>{this.state.servicio ? <div> - {Global.DV(this.state.servicio)}</div> : <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}</Button>
                      <UncontrolledTooltip placement="top" target="asyntecPortalDVServicio">
                        Dígito Verificador
                    </UncontrolledTooltip>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
              </FormGroup>
              {!Global.csd ?
              <FormGroup row>
                <Label for="documento" sm={6}><span>Número de Documento</span></Label>
                <Col sm={6}>
                  <Input bsSize="sm" type="number" name="documento" />
                </Col>
              </FormGroup>:
              <FormGroup row>
              <Col sm={12}>
                <ReCAPTCHA
                  className="float-right"
                  sitekey={Global.apiKeyCaptcha}
                  onChange={value => this.setState({ captcha: value })}
                />
              </Col>
            </FormGroup>}
              {_.map(this.state.mensajes, (m, i) => <Alert key={i} color={m.tipo}>
                {m.texto}
              </Alert>)
              }
              <Button disabled={!this.state.servicio||(Global.csd&&!this.state.captcha)} type="submit" color="primary">Consultar</Button>
            </Form>
          </TabPane>
          {Global.buscaDireccion &&
            <TabPane tabId={2}>
              {selectEmpresa}
              <Form autocomplete="off" className="text-sm-right" onSubmit={e => {
                e.preventDefault();
                this.props.history.push({
                  pathname: '/paga',
                  state: { empresa: this.state.empresa, comuna: this.state.comuna, calle: this.state.calle, noCasa: this.state.noCasa, noDpto: this.state.noDpto, noCasa2: this.state.noCasa2, captcha: this.state.captcha }
                })
              }}>
                <Row form>
                  <Col sm={6}>
                    <FormGroup>
                      <Label for="calle">{"Calle"}</Label>
                      <Autosuggest
                        suggestions={this.state.calles}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested.bind(this)}
                        onSuggestionsClearRequested={() => { }}//tiraba warning
                        getSuggestionValue={this.GetSuggestionValue.bind(this)}
                        renderSuggestion={this.RenderSuggestion.bind(this)}
                        inputProps={{ autocomplete:"off", placeholder: "", value: this.state.calle, onChange: this.onChangeAddress.bind(this), name: "calle" }}
                        // onSuggestionSelected={this.SuggestionSelected}
                        // shouldRenderSuggestions={this.ShouldRenderSuggestions}
                        onSuggestionsUpdateRequested={this.DebouncedBuscarCalles.bind(this)}
                        theme={{
                          container: 'react-autosuggest__container',
                          containerOpen: 'react-autosuggest__container--open',
                          input: this.state.calle === '' ? 'form-control is-invalid form-control-sm' : 'form-control form-control-sm',
                          // suggestionsContainer: 'react-autosuggest__suggestions-container',
                          suggestion: 'react-autosuggest__suggestion',
                          suggestionHighlighted: 'react-autosuggest__suggestion--focused',
                          suggestionsList: 'react-autosuggest__suggestions-container',
                          sectionTitle: 'react-autosuggest__section-title',
                          suggestionsContainer: 'react-autosuggest__section-suggestions-container'
                        }}
                      />
                      <Input invalid={this.state.calle === ""} hidden />
                      <FormFeedback>Requerido</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={3}>
                    <FormGroup>
                      <Label for="noCasa">{"Número"}</Label>
                      <Input autocomplete="off" invalid={this.state.noCasa === ""} onChange={e => this.setState({ noCasa: e.target.value })} bsSize="sm" type="text" name="noCasa" />
                      <FormFeedback>Requerido</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={3}>
                    <FormGroup>
                      <Label for="noDpto">Dpto/Casa</Label>
                      <Input autocomplete="off" onChange={e => this.setState({ noDpto: e.target.value })} bsSize="sm" type="text" name="noDpto" />
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col sm={6}>
                    <FormGroup>
                      <Label for="comuna">{"Comuna"}</Label>
                      <Autosuggest
                        suggestions={this.state.comunas}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedComuna.bind(this)}
                        onSuggestionsClearRequested={() => { }}//tiraba warning
                        getSuggestionValue={this.GetSuggestionValueComuna.bind(this)}
                        renderSuggestion={this.RenderSuggestionComuna.bind(this)}
                        inputProps={{ autocomplete:"off", placeholder: "", value: this.state.comuna, onChange: this.onChangeComuna.bind(this), name: "comuna" }}
                        // onSuggestionSelected={this.SuggestionSelected}
                        // shouldRenderSuggestions={this.ShouldRenderSuggestions}
                        onSuggestionsUpdateRequested={this.DebouncedBuscarComuna.bind(this)}
                        theme={{
                          container: 'react-autosuggest__container',
                          containerOpen: 'react-autosuggest__container--open',
                          input: this.state.comuna === '' ? 'form-control form-control-sm is-invalid' : 'form-control form-control-sm',
                          // suggestionsContainer: 'react-autosuggest__suggestions-container',
                          suggestion: 'react-autosuggest__suggestion',
                          suggestionHighlighted: 'react-autosuggest__suggestion--focused',
                          suggestionsList: 'react-autosuggest__suggestions-container',
                          sectionTitle: 'react-autosuggest__section-title',
                          suggestionsContainer: 'react-autosuggest__section-suggestions-container'
                        }}
                      />
                      <Input invalid={this.state.comuna === ""} hidden />
                      <FormFeedback>Requerido</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup row>
                  <Col sm={12}>
                    <ReCAPTCHA
                      className="float-right"
                      sitekey={Global.apiKeyCaptcha}
                      onChange={value => this.setState({ captcha: value })}
                    />
                  </Col>
                </FormGroup>
                <Button type="submit" color="primary" disabled={!this.state.captcha || !this.state.calle}>Consultar</Button>
              </Form>
            </TabPane>}
        </TabContent>
      </div>
    )
  }
}