import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Label, Input, FormGroup } from 'reactstrap';
import _ from "underscore";
import UploadFile from "./UploadFile"
import Select from "react-select"

const Global = {
    Acciones: {
        RUT: {
            DV: function (T) {
                var M = 0, S = 1;
                for (; T; T = Math.floor(T / 10))
                    S = (S + T % 10 * (9 - M++ % 6)) % 11;
                return S ? S - 1 : 'k';
            }
        },
        Utiles:{
            InputNumerico(aceptaNegativos, esDecimal, event) {
                var value = event.target.value;
                var accept = false;
                if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 13) {
                  accept = true;
                } else if (aceptaNegativos && event.charCode == 45 && value.indexOf("-") == -1) {
                  accept = true;
                } else if (esDecimal && event.charCode == 46 && value.indexOf(".") == -1) {
                  accept = true;
                } else {
                  accept = false;
                }
                if (!accept) {
                  event.preventDefault();
                }
              }
        }
    }
}

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value; //assign the value of ref to the argument
    }, [value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
}

var isEmpty = function (data) {
    if (typeof (data) === 'object') {
        if (JSON.stringify(data) === '{}' || JSON.stringify(data) === '[]') {
            return true;
        } else if (!data) {
            return true;
        }
        return false;
    } else if (typeof (data) === 'string') {
        if (!data.trim()) {
            return true;
        }
        return false;
    } else if (typeof (data) === 'undefined') {
        return true;
    } else {
        return false;
    }
}

const InputCustomFieldForm = ({ customField, setCustomFieldValue, GetFotoCF, OpcionesCustomFields, opciones, forzarReadonly = false, MostrarDireccion, MostrarFirma }) => {
    customField = {
        nombre: customField.FieldName,
        value: isEmpty(customField.Value) ? "" : customField.Value,
        type: customField.Type,
        optionKey: isEmpty(customField.OptionKey) ? "" : customField.OptionKey,
        readOnly: customField.b_read_only || forzarReadonly,
        required: customField.mandatory || customField.Mandatory,
        Opciones: customField.opciones,
        ...customField
    }

    const { nombre, type, optionKey, readOnly, value, required, Opciones } = customField;
    const valor = value != null ? value : null
    const [dvRut, setDvRut] = useState("");
    const esRut = nombre?.toUpperCase() == "RUT";
    const [archivo, setArchivo] = useState([])
    const [valNoJson, setValNoJson] = useState(null)
    const NombreMayusSinTildes = nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()
    const EsUbicacion = false;//type == 6 || NombreMayusSinTildes.includes("DIRECCION") || NombreMayusSinTildes.includes("UBICACION") || NombreMayusSinTildes.includes("LUGAR")
    const prevValue = usePrevious(value)

    useEffect(() => {
        archivo.map((file, i) => {
            if (i == 0) {
                const reader = new FileReader()
                reader.onload = (event) => {
                    setCustomFieldValue(JSON.stringify({ nombre: file.name, b64: event.target.result }))
                }
                reader.readAsDataURL(file)
            }
        })
    }, [archivo])

    useEffect(() => {
        if (type == 9) {
            if (prevValue && !isJsonString(prevValue)) {
                setValNoJson(prevValue)
            } else if (valor && !isJsonString(valor)) {
                setValNoJson(valor)
            }

            if (valor && !isJsonString(valor)) {
                setArchivo([])
            }
        }
    }, [valor])

    useEffect(() => {
        if (esRut && value) {
            setDvRut(Global.Acciones.RUT.DV(value))
        }
    }, [customField]);

    return <>
        <Label className={(!value || (type == -1 && value.some(e => !e.Value))) && required ? "text-danger" : ""}>{!value && required ? `${nombre} *` : nombre}</Label>
        <FormGroup >
            {
                type == 1 || type == 5 || type == 8 || type == 6 ?
                    <>
                        {
                            esRut ?
                                <>

                                    <Input style={{ width: "60%", display: "inline" }} type="text" size="sm" onChange={e => { setCustomFieldValue(e.target.value); setDvRut(Global.Acciones.RUT.DV(e.target.value)) }} onKeyPress={(event) => { Global.Acciones.Utiles.InputNumerico(false, false, event) }} value={valor} readOnly={readOnly} />
                                    <div style={{ display: "inline-block" }}>&nbsp; - &nbsp;</div>
                                    <Input style={{ display: "inline-block" }} type="text" className="input-sm dv-input" value={dvRut} readOnly={true} />
                                </>
                                : EsUbicacion && MostrarDireccion ?
                                    <div className="input-group">
                                        <Input type="text" size="sm" onChange={e => setCustomFieldValue(e.target.value)} value={valor} readOnly={readOnly} onKeyPress={(event) => { return type != 8 || Global.Acciones.Utiles.InputNumerico(false, false, event) }} />
                                        <span className="input-group-btn">
                                            <button style={{ borderWidth: "1px" }} className="btn btn-sm btn-success" onClick={() => { MostrarDireccion({ abrir: true, dir: valor, id: customField.FieldID, readOnly: readOnly }) }} type="button"><span className="glyphicon glyphicon-map-marker"></span></button>
                                        </span>
                                    </div>
                                    :
                                    <Input type={type == 8 ? 'number' : "text"} size="sm" onChange={e => setCustomFieldValue(e.target.value)} value={valor} readOnly={readOnly} onKeyPress={(event) => { return type != 8 || Global.Acciones.Utiles.InputNumerico(false, false, event) }} />
                        }
                    </>

                    :

                    type == 3 ?

                        <Select
                            // se valida ya que hay optionkey con valor 0
                            value={typeof customField.OptionKey === 'boolean' ? customField.OptionKey * 1 : customField.OptionKey}
                            onChange={e => setCustomFieldValue(e?.value)}
                            options={Opciones.map(x => ({ label: x.OptionValue, value: x.OptionKey }))}
                            clearable
                            placeholder="Seleccionar..."
                            noResultsText="Sin resultados"
                            disabled={readOnly}
                        />
                        :
                        type == 7 || type == 2 ?
                            <input
                                type="datetime-local"
                                value={valor?.replace(/\.\d+Z*$/g, "")}
                                disabled={readOnly}
                                onChange={e => setCustomFieldValue(e.target.value)}
                                placeholderText=" Seleccione fecha "
                                className="form-control input-sm"
                                step="60"
                            />

                            : type === 10 ? // componente de firma
                                <div className="input-group">
                                    {/* <Input type="text" size="sm" value={value ? 'OK' : "X"} readOnly /> */}
                                    <i className={`fa fa-${value ? 'check' : 'times'}`} aria-hidden="true"></i>
                                    <span className="input-group-btn" style={{ marginLeft: '10px' }}>
                                        <button style={{ borderWidth: "1px" }} className="btn btn-sm btn-success" onClick={() => MostrarFirma({ abrir: true, valor, id: customField.FieldID, readOnly: readOnly })} type="button"><i className="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                                    </span>
                                </div>

                                : type == 9 ? <>
                                    {valNoJson ? <a href="#" onClick={ev => {
                                        ev.preventDefault();
                                        ev.persist();
                                        if (!ev.target.on) {
                                            ev.target.on = true
                                            ev.target.style.opacity = 0.3
                                            GetFotoCF(customField.id_evento, customField.FieldID, valNoJson.replace(/^CF\-\d+\-\d+\-/, ""), o => {
                                                if (o) {
                                                    var link = document.createElement('a');
                                                    link.href = "data:application/octet-stream;base64," + o;
                                                    link.download = valNoJson.replace(/^CF\-\d+\-\d+\-/, "");
                                                    link.click();
                                                    ev.target.on = null
                                                    ev.target.style.opacity = 1
                                                } else {
                                                    ev.target.on = null
                                                    ev.target.style.opacity = 1
                                                }
                                            })
                                        }
                                    }}>{valNoJson.replace(/^CF\-\d+\-\d+\-/, "")}</a> : null}
                                    {readOnly?valNoJson?null:<Input size="sm" value={""} readOnly />:<UploadFile setArchivos={setArchivo} archivos={archivo} />}
                                </>

                                    : type === -1 ? <div className="input-group">
                                        <Input type="text" size="sm" value={value.map(x => x.Value).sort().join(' ')} readOnly={readOnly} />
                                        <span className="input-group-btn">
                                            <button style={{ borderWidth: "1px" }} className="btn btn-sm btn-success" onClick={() => { MostrarDireccion({ abrir: true, readOnly: readOnly }) }} type="button"><span className="glyphicon glyphicon-map-marker"></span></button>
                                        </span>
                                    </div> : null
            }
        </FormGroup>
    </>

}

export default React.memo(InputCustomFieldForm)

