import React, { useEffect, useState } from 'react';
import { Row, Col, Button, FormGroup, Label, Input, Form, Card, CardHeader, CardBody, Collapse } from 'reactstrap';
import { withRouter } from 'react-router';
import { Global } from '~/App';
import _ from 'underscore';
import API from '~/API';
import GriddleCasero from '~/components/GriddleCasero'
import moment from 'moment';

const Requerimientos = (props) => {

    const [suscripciones, setSuscripciones] = useState([])
    var recaudadores = Object.keys(props.Servicio.Empresa?.otros?.Suscripciones||{});
    const [form, setForm] = useState({recaudador:recaudadores.length == 1 ? recaudadores[0]:undefined })

    const columns = [
        { key: "IdServicioPat", name: "Numero" },
        { key: "Origen", name: "Recaudador" },
        { key: "Activo", name: "Estado", formatter: ({ value, griddleKey, rowData }) => value ? "Activo" : "Inactivo" },
        { key: "FechaInicio", name: "Fecha Creacion", formatter: ({ value }) => moment(value).format("DD/MM/YYYY") },
        { key: "FechaTermino", name: "Fecha Cierre", formatter: ({ value }) => value ? moment(value).format("DD/MM/YYYY") : null }
    ];

    useEffect(() => {
        if (!props.Servicio.idServicio) {
            props.history.replace("/administra")
        } else {
            API.Call(`Recaudacion/${props.Servicio.empresa}/Suscripcion/${props.Servicio.idServicio}/Listado`).then(ev => {
                setSuscripciones(ev)
            });
        }

    }, [])
    const iframe = {}
    try {
        iframe.in = window.self !== window.top
    } catch {
        iframe.in = true;
    }
    return <>
        {suscripciones.some(o => o.Activo) ? null :
        <diV className="text-right pb-2">
            <Form method="post" target={iframe.in ? "_blank" : null} action={form.recaudador ? `${Global.UrlApi}/Recaudacion/${props.Servicio.empresa}/servicio/${props.Servicio.idServicio}/suscripcion/${Global.toTitleCase(form.recaudador)}/Generar` : ""}>
                {recaudadores.includes("Khipu") &&
                    <FormGroup check inline>
                        <Label check>
                            <Input type="radio" checked={form.recaudador == "Khipu"} onClick={() => setForm({ ...form, recaudador: "Khipu" })} />{' '}
                            <img src="https://s3.amazonaws.com/static.khipu.com/buttons/2015/110x50-transparent.png" width="80" />
                        </Label>
                    </FormGroup>}
                    {API.Cookie && <input type="hidden" name="token" value={API.Cookie} />}
                    <Button disabled={!form.recaudador} type="submit" color="primary">Suscribir Pago Automático</Button>
            </Form></diV>}
        <Row>
            <Col sm={12} className="bordeIzquierdo">
                <GriddleCasero
                    data={suscripciones}
                    columns={columns}
                />
            </Col>
        </Row>
    </>
}


export default withRouter(Requerimientos);