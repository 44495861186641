import React, { Component } from 'react';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import { Global } from '~/App'

export default class ModalPorQue extends Component {
  render() {
    return (
      <div>
        <a href="#" onClick={e => e.preventDefault()}><small id="AsyntecPortalClientesPorQueDatos">
          ¿Por qué debo ingresar estos datos?
      </small></a>
        <UncontrolledPopover trigger="legacy" container={document.getElementById('Portal-Clientes')} placement="left-start" target="AsyntecPortalClientesPorQueDatos">
          <PopoverBody>
            <p>Para validar que sea cliente, debe contar con alguna boleta o factura de su cuenta.</p>
            {!Global.csd&&<p>Debe ingresar su número de cliente y el número del documento.</p>}
          </PopoverBody>
        </UncontrolledPopover>
      </div>
    )
  }
}